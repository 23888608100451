import Home from "./pages/Home";
import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import DataRepository from "./pages/DataRepository";
import Publications from "./pages/Publications";
import Press from "./pages/Press";
import { CssBaseline, ThemeProvider } from "@mui/material";
import FAQ from "./pages/FAQ";
import { DataProvider } from "./hooks/useFilterData";
import { ExplorerDataProvider } from "./hooks/useExplorerData";
import BackgroundProvider from "./components/BackgroundProvider";
import About from "./pages/About";
import theme from "./theme";
import { useEffect } from "react";
import Contributors from "./pages/contributors/index";
import WebDataExplorer from "./pages/WebDataExplorer";
import DatasetExplorer from "./pages/DatasetExplorer";
import ExplorerIntroduction from "./pages/ExplorerIntroduction";
import DataProvenanceExplorer from "./pages/DataProvenanceExplorer";
import WebPaper from "./pages/WebPaper";

function App() {
  return (
    <BrowserRouter>
      {/* <ScrollToTop /> */}
      <CssBaseline />
      <DataProvider>
        <ExplorerDataProvider>
          <ThemeProvider theme={theme}>
            <BackgroundProvider>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/data-repository" element={<DataRepository />} />
                <Route path="/publications" element={<Publications />} />
                <Route path="/press" element={<Press />} />
                <Route
                  path="/data-provenance-explorer"
                  element={<DataProvenanceExplorer />}
                />
                <Route
                  path="/explorer-introduction"
                  element={<ExplorerIntroduction />}
                />
                <Route
                  path="/data-provenance-explorer/dataset-explorer"
                  element={<DatasetExplorer />}
                />
                {/* <Route path="/faq" element={<FAQ />} /> */}
                <Route path="/about" element={<About />} />
                <Route path="/contributors" element={<Contributors />} />
                <Route
                  path="/data-provenance-explorer/web-data-explorer"
                  element={<WebDataExplorer />}
                />
                <Route
                  path="/consent-in-crisis-paper"
                  element={<WebPaper />}
                />
              </Routes>
            </BackgroundProvider>
          </ThemeProvider>
        </ExplorerDataProvider>
      </DataProvider>
    </BrowserRouter>
  );
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [pathname]);

  return <></>;
}

export default App;
