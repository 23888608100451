export default function convertToSunburstFormat(data, groups, field) {
  let categoryGroups = {
    name: `${field}_groups`,
    children: [],
  };

  // Create a dictionary to hold field counts
  let fieldCounts = {};
  for (let item of data) {
    if (item[field]) {
      for (let value of item[field]) {
        if (value in fieldCounts) {
          fieldCounts[value]++;
        } else {
          fieldCounts[value] = 1;
        }
      }
    }
  }

  for (let [groupName, values] of Object.entries(groups)) {
    let group = {
      name: groupName,
      children: [],
    };
    for (let value of values) {
      if (value in fieldCounts) {
        group.children.push({
          name: value,
          value: fieldCounts[value],
        });
      }
    }
    if (group.children.length > 0) {
      categoryGroups.children.push(group);
    }
  }

  return categoryGroups;
}
