import { Skeleton } from "@mui/material";

export default function SkeletonLoader() {
  return (
    <Skeleton
      variant="rectangular"
      height={"600px"}
      sx={{ mx: "auto", maxWidth: "1000px" }}
      width={"100%"}
    ></Skeleton>
  );
}
