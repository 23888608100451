import { Stack, Typography } from "@mui/material";
import PaperAndRepository from "./PaperAndRepository";

const DataExplorerHeader = ({
  title,
  description,
  explorerPaper,
}: {
  title: string;
  description: string;
  explorerPaper: string;
}) => {
  return (
    <Stack
      sx={{ py: 8 }}
      gap={{ xs: "2rem", md: "3rem" }}
      alignItems={"center"}
    >
      <Typography
        textAlign={{ xs: "center" }}
        sx={{ fontSize: { xs: "3rem", md: "4rem" } }}
        // sx={{
        //   pl: { lg: 8 },
        //   [theme.breakpoints.down("md")]: {
        //     fontSize: "40px",
        //   },
        // }}>
      >
        {title}
      </Typography>
      {description != '' ? <Typography
        textAlign={{ xs: "center" }}
        sx={{ fontSize: { xs: "1.5rem", md: "1.75rem" } }}
      >
        {description}
      </Typography> : null}
      <PaperAndRepository paper={explorerPaper}/>
    </Stack>
  );
};

export default DataExplorerHeader;
