import { Typography, ListItem, Box, useTheme, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import Person2Icon from "@mui/icons-material/Person2";

interface ProfileCardProps {
  item: {
    name: string;
    affiliate_title: string;
    country_code: string;
    profile_img_url?: string;
    personal_website: string;
    affiliate?: string;
  };
}

const ProfileCard: React.FC<ProfileCardProps> = ({ item }) => {
  const theme = useTheme();
  console.log(item.affiliate);
  return (
    <ListItem
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: 0,
        maxWidth: "100%",
        width: { xs: "200px", sm: "250", md: "314px" },

        ".MuiTypography-root": {
          fontSize: { md: "1.25rem", xs: "1rem" },
        },
        ".profile-pic": {
          width: "100%",
          aspectRatio: "1 / 1",
          marginBottom: "10px",
          borderRadius: "15px",
          background: "#fff",
        },
      }}
    >
      <img
        className="profile-pic"
        src={item.profile_img_url || "/profile.png"}
        alt=""
        style={{
          objectFit: "cover",

          backgroundColor: "#fff",
        }}
      />

      <Typography variant="h4" style={{ margin: 0, fontWeight: 600 }}>
        <Link
          to={item.personal_website.includes('\/\/') ? item.personal_website : `\/\/${item.personal_website}`}
          target="_blank"
          style={{ color: theme.customColors.primary.prusianBlue }}
        >
          {item.name} ({item.country_code})
        </Link>
      </Typography>
      {/* <Typography variant="h4" style={{ margin: 0, fontWeight: 500 }}>
        {item.affiliate_title}
      </Typography> */}

      {item.affiliate && (
        <Typography
          variant="h4"
          style={{ margin: 0, fontWeight: 500 }}
        >
          {item.affiliate}
        </Typography>
      )}
    </ListItem>
  );
};

export default ProfileCard;
