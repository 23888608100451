import { Stack, Typography } from "@mui/material";
import React from "react";

type MetricComparison = [number, number];

export type MetricsComparison = {
  Collections: MetricComparison;
  Datasets: MetricComparison;
  Dialogs: MetricComparison;
  Languages: MetricComparison;
  "Task Categories": MetricComparison;
  Topics: MetricComparison;
  "Text Domains": MetricComparison;
  "Text Sources": MetricComparison;
  "Synthetic Text": number;
};

export default function Metrics({ metrics }: { metrics: MetricsComparison }) {
  return (
    <Stack
      direction={"row"}
      gap={"50px"}
      justifyContent={{ xs: "space-evenly", xl: "space-between" }}
      flexWrap={"wrap"}
      sx={{}}
    >
      {Object.entries(metrics).map(([key, values]) => (
        <Stack
          key={key}
          sx={{
            "*": {
              textAlign: "center",
              whiteSpace: "nowrap", // corrected property name
            },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {values instanceof Array ? (
            <>
              <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                {values[0]}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ fontSize: "0.8rem", textAlign: "center" }}
              >
                / {values[1]}
              </Typography>
            </>
          ) : (
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              {values} %
            </Typography>
          )}
          <Typography>{key}</Typography>
        </Stack>
      ))}
    </Stack>
  );
}
