import { SxProps, Typography } from "@mui/material";
import React from "react";

export default function Titlebar({
  children,
  sx = {},
}: {
  children: any;
  sx?: SxProps;
}) {
  return (
    <Typography
      sx={{
        textAlign: "center",
        ...sx,
        //  maxWidth: "1000px",
        mx: "auto",
      }}
      variant="h4"
    >
      {children}
    </Typography>
  );
}
