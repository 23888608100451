import { Link, useLocation } from "react-router-dom";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

import XIcon from "@mui/icons-material/X";
import DiscordIcon from "../icons/DiscordIcon";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import { DATA_REPO_LINK, MAX_WIDTH } from "../constants";
import convertToViewportUnits from "../helpers/convertToViewportUnits";
import { useEffect, useState } from "react";
import Logo from "../icons/Logo";

const research = [
  {
    name: "Data Repository",
    link: DATA_REPO_LINK,
  },
  { name: "Data Provenance Explorer", link: "/explorer-introduction" },
  { name: "Publications", link: "/publications" },
];

const dataProvenanceInitiative = [
  { name: "About", link: "/about" },
  // { name: "FAQ", link: "/faq" },
  { name: "Press", link: "/press" },
  { name: "Contributors", link: "/contributors" },
];

const socialMediaLinks = [
  { img: <DiscordIcon />, link: "https://discord.com" },
  { img: <XIcon />, link: "https://twitter.com" },
  { img: <LinkedInIcon />, link: "https://linkedin.com" },
  { img: <GitHubIcon />, link: "https://github.com" },
];

const Footer = () => {
  const [path, setPath] = useState<string>("/");
  const location = useLocation();
  const theme = useTheme();

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  return (
    <Box
      sx={{
        mt: 5,
        mx: "auto",
        maxWidth: `${MAX_WIDTH}px`,

        padding: `${path === "/contributors" || path === "/about" || path === "/" || path === "data-provenance-explorer" ? convertToViewportUnits(3) : convertToViewportUnits(2)} ${convertToViewportUnits(4)}`,

        color: theme.customColors.primary.aliceBlue,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: "30px", sm: "60px" },
          marginBottom: { xs: "30px", sm: "80px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              color: theme.customColors.supplementary.bluish,
              fontSize: 16,
            }}
          >
            Our Research
          </Typography>
          {research.map((link, index) =>
            link.name === "Data Repository" ? (
              <Box
                key={link.name}
                sx={{
                  "&>*": {
                    justifyContent: "start",
                  },
                }}
              >
                <Link
                  key={index}
                  to={link.link}
                  target="_blank"
                  style={{
                    fontSize: 20,
                    color: theme.customColors.primary.aliceBlue,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography component={"span"} variant="h5">
                    {link.name}
                  </Typography>
                  <ArrowOutwardIcon />
                </Link>
              </Box>
            ) : (
              <Link
                key={link.name}
                to={link.link}
                style={{
                  fontSize: 20,
                  color: theme.customColors.primary.aliceBlue,
                }}
              >
                <Typography component={"span"} variant="h5">
                  {link.name}
                </Typography>
              </Link>
            ),
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              color: theme.customColors.supplementary.bluish,
              fontSize: 16,
            }}
          >
            Data Provenance Initiative
          </Typography>
          {dataProvenanceInitiative.map((link, index) => (
            <Link
              key={index}
              to={link.link}
              style={{
                textDecoration: "none",
                color: theme.customColors.primary.aliceBlue,
              }}
            >
              <Typography component={"span"} variant="h5">
                {link.name}
              </Typography>
            </Link>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            gap: "30px",
            alignItems: "start",
          },
        }}
      >
        <Typography variant="h5">
          Data Provenance Initiative &copy; 2024
        </Typography>
        {/* <Box
            sx={{
              display: "flex",
              gap: { xs: "10px", lg: "15px" },
              alignItems: "center",
              justifyContent: { xs: "space-evenly", sm: "space-between" },
              alignSelf: "stretch",

              ".discord-icon": {
                width: { xs: "35px", sm: "45px", md: "55px" },
                height: { xs: "35px", sm: "45px", md: "55px" },
              },
              "*": {
                fontSize: { xs: "30px", sm: "40px", md: "50px" },
              },
              ".icon-link": {
                display: "grid",
                placeItems: "center",
              },
            }}
          >
            {socialMediaLinks.map((link, index) => (
              <Link
                key={index}
                className="icon-link"
                to={link.link}
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: theme.customColors.primary.aliceBlue,
                  fill: theme.customColors.primary.aliceBlue,
                }}
              >
                {link.img}
              </Link>
            ))}
          </Box> */}
      </Box>
    </Box>
  );
};

export function ExplorerFooter() {
  return (
    <Stack
      direction={"row"}
      gap={{ xs: 1, sm: 3 }}
      sx={{
        maxWidth: `${MAX_WIDTH}px`,
        // background: "red",
        px: { xs: "0.5rem", sm: "25px", md: "52px" },
        mx: "auto",
        py: { xs: "28px", md: "32px" },
        alignItems: "center",
      }}
    >
      <IconButton
        LinkComponent={Link}
        {...({ to: "/" } as any)}
        sx={(theme) => ({
          padding: 0,
          "&>*": {
            width: { xs: "50px", md: "50px" },
            height: { xs: "50px", md: "50px" },
            fill: "black",
          },
        })}
      >
        <Logo />
      </IconButton>
      <Typography sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem", md: "2rem" } }}>
        Data Provenance Explorer
      </Typography>
    </Stack>
  );
}

export default Footer;
