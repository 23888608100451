import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import Logo from "../icons/Logo";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material";

const LinkStyled = styled(Link)({
  textDecoration: "none",
  color: "inherit",
  display: "grid",
  placeItems: "center",
  fontWeight: 500,
  fontSize: "1rem",
});

const LogoLink = () => {
  const location = useLocation();
  const path = location.pathname;

  return (
    <LinkStyled to="/">
      <IconButton
        sx={(theme) => ({
          padding: 0,
          "&>*": {
            width: { xs: "50px", md: "50px" },
            height: { xs: "50px", md: "50px" },
            fill:
              path === "/" ||
              path === "/data-provenance-explorer/dataset-explorer" ||
              path === "/data-provenance-explorer" ||
              path === "/explorer-introduction" ||
              path === "/data-provenance-explorer/web-data-explorer"
                ? theme.customColors.primary.aliceBlue
                : theme.customColors.primary.prusianBlue,
          },
        })}
      >
        <Logo />
      </IconButton>
    </LinkStyled>
  );
};

const Navbar = ({ sx = {} }: { sx?: SxProps }) => {
  const theme = useTheme();

  const location = useLocation();
  const path = location.pathname;

  return (
    <Box
      className="my-navbar"
      sx={{
        p: 0,
        // px: "2vw",
        // padding: "5vw",
        backgroundColor:
          path === "/data-provenance-explorer/dataset-explorer" ||
          path === "/data-provenance-explorer" ||
          path === "/data-provenance-explorer/web-data-explorer"
            ? theme.customColors.primary.prusianBlue
            : "inherit",
        color:
          path === "/data-provenance-explorer/dataset-explorer" ||
          path === "/data-provenance-explorer" ||
          path === "/data-provenance-explorer/web-data-explorer"
            ? theme.customColors.primary.aliceBlue
            : "inherit",
        ...sx,
      }}
      component="header"
    >
      {/* Desktop Navbar */}
      <Box
        className="desktop-navbar"
        sx={{
          px: "45px",
          py: "26px",
          display: { xs: "none", lg: "flex" },
          justifyContent: "space-between",
          alignItems: "center",

          [theme.breakpoints.down("lg")]: {
            // flexDirection: "column",
            gridTemplateColumns: "1fr 1fr 1fr",
            justifyItems: "center",
            "&>*.menu-icon": {
              justifySelf: "start",
            },
            "&>*:last-child": {
              justifySelf: "end",
            },
            gap: "10px",
          },
        }}
      >
        {/* Left side of Navbar */}
        <Box
          sx={{
            gap: { xs: "20px", lg: "40px" },
            display: { xs: "none", lg: "flex" },
            alignItems: "center",
            [theme.breakpoints.down("lg")]: {
              justifyContent: "space-between",
            },
            ".link": {
              display: { xs: "none", lg: "inline-block" },
            },
          }}
        >
          <LogoLink />

          <LinkStyled
            className="link"
            to="/about"
            style={{ marginLeft: "100px" }}
          >
            About
          </LinkStyled>
        </Box>

        {/* Right side of Navbar */}
        <Stack
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            gap: "75px",
            flexDirection: "row",
            ".link": {
              display: { xs: "none", lg: "inline-block" },
            },
          }}
        >
          <LinkStyled className="link" to="/press">
            Press
          </LinkStyled>
          <LinkStyled className="link" to="/publications">
            Publications
          </LinkStyled>
          <LinkStyled className="link" to="/contributors">
            Contributors
          </LinkStyled>

          {/* <LinkStyled className="link" to="/faq">
            FAQ
          </LinkStyled> */}

          <TryExplorerButton
            sx={{ [theme.breakpoints.down("lg")]: { display: "none" } }}
          />
        </Stack>
      </Box>
      <MobileNavbar />
    </Box>
  );
};

function MobileNavbar() {
  const location = useLocation();
  const path = location.pathname;
  const theme = useTheme();

  const [menuOpen, setMenuOpen] = useState(false);

  function closeDrawer() {
    setMenuOpen((o) => !o);
  }

  return (
    <Box
      sx={{
        px: { xs: "0.5rem", sm: "24px" },
        py: { xs: "12px", sm: "21px" },
        display: { xs: "flex", lg: "none" },
        justifyContent: "space-between",
      }}
    >
      <LogoLink />
      <IconButton
        className="menu-icon"
        onClick={() => setMenuOpen((o) => !o)}
        sx={{
          p: 0,
          "*": {
            fontSize: "40px !important",
            fill:
              path === "/" ||
              path === "/data-provenance-explorer/dataset-explorer" ||
              path === "/data-provenance-explorer/web-data-explorer" ||
              path === "/data-provenance-explorer" ||
              path === "/explorer-introduction"
                ? theme.customColors.primary.aliceBlue
                : theme.customColors.primary.prusianBlue,
          },
        }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        onClose={() => setMenuOpen(false)}
        anchor="right"
        PaperProps={{
          sx: {
            backgroundColor: theme.customColors.primary.prusianBlue,
            color: theme.customColors.primary.aliceBlue,
            width: "80%",
            zIndex: 2,
            display: { lg: "none" },
          },
        }}
        sx={{
          display: { lg: "none" },
          ".mobile-menu-links": {
            my: 3,
            pl: 4,
            textAlign: "start",
            display: "block",
            fontSize: "32px",
          },
        }}
        open={menuOpen}
      >
        <IconButton
          className="menu-icon"
          onClick={() => setMenuOpen((o) => !o)}
          sx={{
            ml: "auto",
            pr: "2vw",
            mt: "2vw",
            "*": {
              fontSize: "40px",
              color: theme.customColors.primary.aliceBlue,
            },
          }}
        >
          <MenuIcon />
        </IconButton>
        <LinkStyled
          onClick={closeDrawer}
          className="mobile-menu-links"
          to={"/about"}
        >
          About
        </LinkStyled>
        <LinkStyled
          onClick={closeDrawer}
          className="mobile-menu-links"
          to={"/press"}
        >
          Press
        </LinkStyled>
        <LinkStyled
          onClick={closeDrawer}
          className="mobile-menu-links"
          to={"/publications"}
        >
          Publications
        </LinkStyled>

        <LinkStyled
          onClick={closeDrawer}
          className="mobile-menu-links"
          to={"/contributors"}
        >
          Contributors
        </LinkStyled>
        <LinkStyled
          onClick={closeDrawer}
          className="mobile-menu-links"
          to={"/explorer-introduction"}
        >
          Data Provenance Explorer
        </LinkStyled>
      </Drawer>
    </Box>
  );
}

export function TryExplorerButton({ sx = {} }: { sx?: SxProps }) {
  // const router = useHistory();
  const navigate = useNavigate();

  return (
    <Button
      variant="outlined"
      color="inherit"
      onClick={() => navigate("/explorer-introduction")}
      sx={{
        alignSelf: "stretch",
        minWidth: "fit-content",
        flexBasis: "0",
        ".try-explorer-link": {
          fontSize: "20px",
        },
        ...sx,
      }}
    >
      <Typography
        className="try-explorer-link"
        style={{
          fontStyle: "italic",
          whiteSpace: "nowrap",
          textDecoration: "none",
          color: "inherit",
          placeItems: "center",
          fontWeight: 500,
          fontSize: "1rem",
        }}
      >
        Try the Explorer
      </Typography>
      {/* <ArrowRightAltIcon
        sx={{
          scale: "2.5 1",
          ml: 3,
        }}
      /> */}
    </Button>
  );
}

export default Navbar;
