import { Box, CircularProgress } from "@mui/material";
import React from "react";

export default function LoadingAnimation({
  loading = true,
  children,
}: {
  loading: boolean;
  children: any;
}): any {
  if (!loading) return children;

  return (
    <Box sx={{ position: "relative" }}>
      {children}
      <Box
        sx={{
          background: "rgba(122,122,122,0.5)",
          position: "absolute",
          display: "grid",
          inset: "0",
          placeItems: "center",
          zIndex: 4,
        }}
      >
        <CircularProgress />
      </Box>
    </Box>
  );
}
