import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControlLabel,
  FormGroup,
  Slider,
  Stack,
  Switch,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";
import { FormEvent, SyntheticEvent, useState } from "react";
import { Close } from "@mui/icons-material";
import { useFilterData } from "../hooks/useFilterData";
import styled from "@emotion/styled";
import { LICENSE_USE_CLASSES } from "../utils/constants";
import { useTheme } from "@mui/material";

const taskCategories = [
  "All",
  "Response Ranking",
  "Translation",
  "Logical and Mathematical Reasoning",
  "Dialog Generation",
  "Miscellaneous",
  "Question Answering",
  "Token Classification",
  "Explanation",
  "Summarization",
  "Code",
  "Language Style Analysis",
  "Open-form Text Generation",
  "Text Classification",
  "Chain-of-Thought",
  "Bias & Toxicity Detection",
  "Short Text Generation",
  "Creative Writing",
  "Creativity",
  "Natural Language Inference",
  "Commonsense Reasoning",
  "Sequence Tagging",
  "Brainstorming",
];

const domainTypes = [
  "All",
  "ML Datasets",
  "Search Queries",
  "Social Media",
  "General Web",
  "Encyclopedias",
  "Entertainment",
  "Biomedical",
  "Education",
  "Exams",
  "News",
  "Human",
  "Commerce",
  "Models",
  "Others",
  "Reviews",
  "Conversations",
  "Template Generated",
  "Code",
  "Legal",
  "Governments",
  "Academic Papers",
  "Books",
  "Unsure",
];

const languages = [
  "All",
  "Code",
  "Indo-European",
  "Sino-Tibetan",
  "Austronesian",
  "Dravidian",
  "Japonic",
  "Koreanic",
  "Turkic",
  "Uralic",
  "Afroasiatic",
  "Atlantic-Congo",
  "Kra-Dai",
  "Other",
];

const dates = { startDate: "1999-12-18", endDate: "2023-12-01" };

const licensedUseCases = [...LICENSE_USE_CLASSES];

export const CodeStyled = styled("span")({
  color: "rgb(9, 171, 59)",
  backgroundColor: "#eee",
  padding: "1px",
});

const Form = () => {
  const theme = useTheme();
  const filterData = useFilterData().data;
  const [date, setDate] = useState([...filterData.date]);
  const [useCase, setUseCase] = useState(filterData.useCase);
  const [selectedLanguages, setSelectedLanguages] = useState([
    ...filterData.selectedLanguages,
  ]);
  const [selectedCategories, setSelectedCategories] = useState([
    ...filterData.selectedCategories,
  ]);
  const [selectedDomainTypes, setSelectedDomainTypes] = useState<string[]>([
    ...filterData.selectedDomainTypes,
  ]);
  const [attribution, setAttribution] = useState(filterData.attribution);
  const [shareAlike, setShareAlike] = useState(filterData.shareAlike);
  const [openAIGeneratedData, setOpenAIGeneratedData] = useState(
    filterData.openAIGeneratedData,
  );

  const { data, setData } = useFilterData();

  const handleDateChange = (_: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
    } else if (Array.isArray(newValue)) {
      setDate([...newValue]);
    }
  };

  const handleUseCaseChange = (_: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      setUseCase(licensedUseCases[newValue]);
    } else if (Array.isArray(newValue)) {
      console.log(newValue);
    }
  };

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const dateToValue = (date: Date) => date.getTime();
  const valueToDate = (value: number) => new Date(value);

  const handleOptionSelect = (selection: string, newValues: string[]) => {
    if (typeof newValues === "string") {
      console.log(newValues);
    } else if (Array.isArray(newValues)) {
      selection === "languages"
        ? setSelectedLanguages(newValues)
        : selection === "taskCategories"
          ? setSelectedCategories(newValues)
          : setSelectedDomainTypes(newValues);
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setData({
      ...data,
      date,
      shouldShowData: true,
      useCase,
      selectedLanguages,
      selectedCategories,
      selectedDomainTypes,
      attribution,
      shareAlike,
      openAIGeneratedData,
    });
  };

  const formTypoStyles: SxProps = {
    fontWeigth: 500,
    fontSize: 20,
    lineHeight: "24.2px",
  };

  const FirstComponent = (
    <Box
      sx={{
        gap: "30px",
        width: "100%",
      }}
    >
      <Stack>
        <Typography variant="h5">
          Select the datasets licensed for these use cases
        </Typography>
        <Box sx={{ p: 1, position: "relative" }}>
          <Typography
            gutterBottom
            color="error"
            sx={{
              display: "inline-block",
              position: "relative",
              left:
                licensedUseCases.indexOf(useCase) >= 4
                  ? "auto"
                  : `calc(${(licensedUseCases.indexOf(useCase) / 3) * 100}%)`,
              // textAlign:
              //   licensedUseCases.indexOf(useCase) >= 3 ? "right" : "left",
              // right: licensedUseCases.indexOf(useCase) >= 3 ? "0%" : "auto",
              transform:
                licensedUseCases.indexOf(useCase) >= 2
                  ? "translateX(-100%)"
                  : "",
            }}
          >
            {useCase}
          </Typography>
          <Slider
            value={licensedUseCases.indexOf(useCase)}
            sx={{
              maxWidth: { xs: "95%", sm: "95%" },
              alignSelf: "stretch",
              mx: "auto",
            }}
            min={0}
            max={3}
            color={"error"}
            onChange={handleUseCaseChange}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="body2">{licensedUseCases[0]}</Typography>
            <Typography variant="body2">{licensedUseCases[3]}</Typography>
          </Box>
        </Box>
      </Stack>
      <FormGroup
        sx={{
          gap: "30px",
        }}
      >
        <FormControlLabel
          control={
            <Switch
              defaultChecked
              color="error"
              onChange={(e) => setAttribution(e.target.checked)}
            />
          }
          label={
            <Typography variant="h5">
              Include Datasets w/ Attribution Requirements
            </Typography>
          }
          sx={{
            alignItems: "start",
          }}
        />

        <FormControlLabel
          control={
            <Switch
              defaultChecked
              color="error"
              onChange={(e) => setShareAlike(e.target.checked)}
            />
          }
          label={
            <Typography variant="h5">
              Include Datasets w/ Share Alike Requirements
            </Typography>
          }
          sx={{
            alignItems: "start",
          }}
        />

        <FormControlLabel
          control={
            <Switch
              color="error"
              onChange={(e) => setOpenAIGeneratedData(e.target.checked)}
            />
          }
          label={
            <Typography variant="h5">
              Always include datasets w/ OpenAI-generated data. (I.e. See{" "}
              <CodeStyled>instructions</CodeStyled> above for details.)
            </Typography>
          }
          sx={{
            alignItems: "start",
          }}
        />
      </FormGroup>
    </Box>
  );

  const SecondComponent = (
    <Stack
      sx={{
        gap: "30px",
        p: 1,
        width: "100%",
      }}
    >
      <Stack sx={{ alignSelf: "stretch" }}>
        <Typography variant="h5">
          Select data release time constraints
        </Typography>
        <Slider
          value={[date[0], date[1]]}
          step={100}
          sx={{
            maxWidth: { xs: "85%", sm: "95%" },
            alignSelf: "stretch",
            mx: "auto",
          }}
          min={new Date(dates.startDate).getTime()}
          max={new Date(dates.endDate).getTime()}
          onChange={handleDateChange}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => formatDate(valueToDate(value))}
          color="error"
        />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="body2">{dates.startDate}</Typography>
          <Typography variant="body2">{dates.endDate}</Typography>
        </Box>
      </Stack>

      <Box
        sx={{
          ".MuiAutocomplete-root ": {
            width: "100%",
          },
        }}
      >
        <Typography variant="h5" sx={{ my: 1 }}>
          Select the languages to cover in your datasets
        </Typography>
        <Autocomplete
          options={languages}
          autoHighlight
          multiple
          value={selectedLanguages}
          onChange={(
            event: SyntheticEvent<Element, Event>,
            newValues: string[],
          ) => handleOptionSelect("languages", newValues)}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option}>
              {option}
            </Box>
          )}
          renderTags={(value: string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                label={option}
                {...getTagProps({ index })}
                key={option}
                style={{
                  backgroundColor: theme.customColors.primary.madder,
                  color: "#fff",
                  borderRadius: "5px",
                }}
                deleteIcon={
                  <Close style={{ color: "#fff", fontSize: "14px" }} />
                }
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Choose an option"
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
      </Box>
    </Stack>
  );

  const ThirdComponent = (
    <Stack
      sx={{
        width: "100%",
        gap: "30px",
        ".MuiAutocomplete-root ": {
          width: "100%",
        },
      }}
    >
      <Box>
        <Typography variant="h5" sx={{ my: 1 }}>
          Select the task categories to cover in your datasets
        </Typography>
        <Autocomplete
          options={taskCategories}
          autoHighlight
          multiple
          value={selectedCategories}
          onChange={(
            event: SyntheticEvent<Element, Event>,
            newValues: string[],
          ) => handleOptionSelect("taskCategories", newValues)}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option}>
              {option}
            </Box>
          )}
          renderTags={(value: string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                label={option}
                {...getTagProps({ index })}
                key={option}
                style={{
                  backgroundColor: theme.customColors.primary.madder,
                  color: "#fff",
                  borderRadius: "5px",
                }}
                deleteIcon={
                  <Close style={{ color: "#fff", fontSize: "14px" }} />
                }
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Choose an option"
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
      </Box>

      <Box>
        <Typography variant="h5" sx={{ ...formTypoStyles, my: 1 }}>
          Select the domain types to cover in your datasets
        </Typography>
        <Autocomplete
          options={domainTypes}
          value={selectedDomainTypes}
          autoHighlight
          multiple
          onChange={(
            event: SyntheticEvent<Element, Event>,
            newValues: string[],
          ) => handleOptionSelect("domainTypes", newValues)}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option}>
              {option}
            </Box>
          )}
          renderTags={(value: string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                label={option}
                {...getTagProps({ index })}
                key={option}
                style={{
                  backgroundColor: theme.customColors.primary.madder,
                  color: "#fff",
                  borderRadius: "5px",
                }}
                deleteIcon={
                  <Close style={{ color: "#fff", fontSize: "14px" }} />
                }
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Choose an option"
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
      </Box>
    </Stack>
  );

  return (
    <form
      style={{
        width: "100%",
        backgroundColor: theme.customColors.supplementary.light,
        padding: "40px 20px",
      }}
      onSubmit={handleSubmit}
    >
      <Stack
        width="100%"
        justifyContent={"space-evenly"}
        gap={{ xs: "10px", md: "15px", xl: "25px" }}
        alignItems={"start"}
        sx={{
          "&>*": {
            p: { xs: "5px", md: "15px" },
          },
          flexDirection: "row",
          [theme.breakpoints.down("lg")]: {
            flexDirection: "column",
            alignItems: "center",
          },
        }}
      >
        {FirstComponent}
        {SecondComponent}
        {ThirdComponent}
      </Stack>

      <Button
        variant="outlined"
        sx={{
          display: "block",
          marginLeft: "auto",
          color: "#fff",
          backgroundColor: theme.customColors.primary.prusianBlue,
          ":hover": { backgroundColor: theme.customColors.primary.madder },
          borderRadius: "10px",
          fontSize: "20px",
          px: 4,

          [theme.breakpoints.down("lg")]: {
            margin: "auto",
            mt: "15px",
          },
        }}
        type="submit"
      >
        Submit
      </Button>
    </form>
  );
};

export default Form;
