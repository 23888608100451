import { Link } from "react-router-dom";
import { Typography, Box, Stack, useTheme } from "@mui/material";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import convertToViewportUnits from "../helpers/convertToViewportUnits";
import { TryExplorerButton } from "../components/Navbar";
import { sliderSettings } from "../config/sliderSettings";
import { DATA_REPO_LINK } from "../constants";

const sliderList = [
  {
    title: "Data Repository",
    link: DATA_REPO_LINK,
    img: "../images/home_slide1.png",
    details:
      "We audited 1800+ datasets. View our data collection",
  },
  {
    title: "Data Provenance Explorer",
    link: "/explorer-introduction",
    img: "../images/home_slide2.png",
    details: "Explore and analyze training datasets",
  },
];

const Home = () => {
  const theme = useTheme();

  return (
    <Box
      className="home-container"
      sx={{
        display: "flex",
        mx: "auto",
        flexGrow: 1,
        maxWidth: "100%",
        justifyContent: { xs: "center", lg: "space-between" },
        alignItems: "center",
        flexDirection: {
          lg: "row",
          xs: "column-reverse",
        },
        gap: { xs: "70px", lg: 0 },
        minHeight: { lg: "100%" },
        px: convertToViewportUnits(3),
        pb: convertToViewportUnits(10),
        pt: convertToViewportUnits(3),
      }}
    >
      <Stack
        direction={"column"}
        alignItems="start"
        sx={{
          maxWidth: "100%",
          "*": {
            textAlign: "start",
          },
          width: { xs: 340, sm: 520, lg: "35%" },
          gap: "20px",
        }}
      >
        <Typography
          sx={{
            color: theme.customColors.primary.aliceBlue,
            lineHeight: { md: "116px" },
          }}
          variant="h1"
        >
          Data Provenance Initiative
        </Typography>
        <Typography
          variant="h4"
          sx={{
            color: theme.customColors.primary.aliceBlue,
          }}
        >
          Uncover the datasets used to train large language models
        </Typography>
        <TryExplorerButton
          sx={{ alignSelf: "start", display: { lg: "none" } }}
        />
      </Stack>

      <Box
        className="slider-parent"
        sx={{
          width: { xs: `min(90vw, 370px)`, sm: 550, lg: 550, xl: "619px" },
          maxWidth: { sm: "min(619px, 90%)" },
          aspectRatio: 0.944,
          flexShrink: 0,
          marginTop: "20px",
          borderRadius: "15px",
          ".slick-dots": {
            "*::before": {
              color: `${theme.customColors.primary.aliceBlue} !important`,
              fontSize: { xs: "9.5px", md: "12px" },
            },
          },
        }}
      >
        <Slider {...sliderSettings}>
          {sliderList.map((item, index) => (
            <Box
              key={index}
              sx={{
                width: "100%",
                pb: { md: 3 },
                pl: { lg: 2, xs: 1 },
                pr: { lg: 0, xs: 1 },
              }}
            >
              <Stack
                sx={{
                  aspectRatio: 0.944,
                  borderRadius: "15px",
                  width: "100%",
                  p: { xs: "10px", md: "20px" },
                  pb: { xs: "42px", md: "3rem" },
                  pt: { xs: "20px", sm: "29px", md: "33px" },
                  px: { xs: "25px", sm: "39px", md: "44px" },
                  display: "inline-flex !important",
                  background: index == 0 ? `url('${require("../images/home_slide1.png")}')` : `url('${require("../images/home_slide2.png")}')`,
                  justifyContent: "space-between",
                }}
              >
                <Link
                  to={item.link}
                  style={{
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      color: theme.customColors.primary.prusianBlue,
                      fontSize: { md: "1.5rem", xs: "1rem" },
                    }}
                  >
                    {item.title}
                  </Typography>
                </Link>
                <Typography
                  style={{
                    justifySelf: "flex-end",
                    color: theme.customColors.primary.prusianBlue,
                    fontSize: "2rem",
                  }}
                >
                  {item.details}
                </Typography>
              </Stack>
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default Home;
