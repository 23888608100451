import { Box, Stack } from "@mui/material";
import WebDataGraph, { Graph } from "../components/WebDataGraph";
import DataExplorerHeader from "../components/DataExplorerHeader";
import BreadcrumbsComp from "../components/BreadcrumbsComp";
import { PAPER_LINK2 } from "../constants";

const Graphs: Graph[] = [
  {
    title: "Trends for Robots.txt and Terms of Service",
    description:
      "We conduct a temporal analysis, from 2016 to April 2024, of the web consent signals in HEAD-C4, 2k of the largest and most critical web domains. The colored regions represent the restriction categories as a portion of the total tokens in HEAD-C4. A web domain is considered restricted if its robots.txt disallows access to AI crawlers from OpenAI, Google, Anthropic, Common Crawl, Cohere, Meta, or the Internet Archive. At the bottom we see restrictions broken down by organization. In all cases there are significant recent spikes in restrictions.",
    image: "/WDEImg1.png",
  },
  {
    title: "Restrictions by Dataset and by Domain",
    description:
      "",
    image: "/WDEImg2.png",
  },
];

const WebDataExplorer = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <BreadcrumbsComp page="Web Data Explorer" />
      <Box sx={{ mx: "auto", mt: "25px", mb: "75px", width: "100%" }}>
        <DataExplorerHeader
          title="Web Data Explorer"
          description="Shifting preferences in how websites allow their data to be used for AI"
          explorerPaper={PAPER_LINK2}
        />
        <Stack
          alignItems="center"
          sx={{
            gap: { xs: "100px", md: "129px" },
            mt: { xs: "50px", md: "75px" },
          }}
        >
          {Graphs.map((graph, index) => (
            <WebDataGraph graph={graph} key={graph.title + index} />
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default WebDataExplorer;
