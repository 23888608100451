import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Box,
  Button,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { FormEvent, SyntheticEvent, useEffect, useState } from "react";
import computeMetrics from "../../utils/computeMetrics";
import { useExplorerData } from "../../hooks/useExplorerData";
import Metrics from "../Metrics";
import getMetricsForDisplay from "../../utils/getMetricsForDisplay";
import type { MetricsComparison } from "../Metrics";

const InspectIndividualDatasets = () => {
  const [datasetOptions, setDatasetOptions] = useState<string[] | null>(null);
  const [stats, setStats] = useState<MetricsComparison | null>(null);
  const [selectedDataset, setSelectedDataset] = useState<{
    [key: string]: any;
  } | null>(null);

  const explorerData = useExplorerData();
  const { metaData } = explorerData;

  useEffect(() => {
    if (explorerData && explorerData.data) {
      const datasetCollection = (explorerData.data as any).map(
        (dataset: any) => dataset["Unique Dataset Identifier"],
      );
      setDatasetOptions(datasetCollection);
      setSelectedDataset(explorerData.data[0]);
    }
  }, [explorerData]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(selectedDataset);
    if (datasetOptions && selectedDataset) {
      const dataset = (explorerData.data as any).filter(
        (data: any) =>
          data["Unique Dataset Identifier"] ===
          selectedDataset["Unique Dataset Identifier"],
      );
      const metrics = computeMetrics(dataset, explorerData.constants);

      if (metrics && metaData) {
        setStats(getMetricsForDisplay(metrics, metaData));
      }
    }
  };

  const handleOptionSelect = (
    event: SyntheticEvent<Element, Event>,
    newValue: string | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined,
  ): void => {
    if (newValue) {
      if (explorerData) {
        const newSelection = (explorerData.data as any).filter(
          (data: any) => data["Unique Dataset Identifier"] === newValue,
        );
        setSelectedDataset(newSelection[0]);
      }
    }
  };

  return (
    <>
      <Box>
        <Box
          style={{
            border: "2px solid #aaa",
            borderRadius: "5px",
            padding: "20px",
          }}
        >
          <Typography>
            Select the dataset in this collection to inspect
          </Typography>
          <form onSubmit={handleSubmit}>
            {datasetOptions && selectedDataset && (
              <Autocomplete
                sx={{ maxWidth: "100%", width: 300, marginY: "20px" }}
                options={datasetOptions}
                autoHighlight
                value={selectedDataset["Unique Dataset Identifier"]}
                onChange={handleOptionSelect}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose an option"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            )}
            <Button
              variant="outlined"
              sx={{
                color: "#aaa",
                border: "#aaa solid 1px",
                borderRadius: "5px",
              }}
              type="submit"
            >
              Submit Selection
            </Button>
          </form>
        </Box>

        {stats && (
          <Box sx={{ my: 3 }}>
            <Metrics metrics={stats} />
          </Box>
        )}
        {selectedDataset && stats && (
          <Box
            sx={{
              display: "grid",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gap: "10px",
                "*": {
                  "word-break": "break-word !important",
                },
              }}
            >
              <Typography
                variant="h5"
                component="h4"
                sx={{
                  fontWeight: "bold",
                }}
              >
                Dataset Information
              </Typography>

              <Typography>
                Unique Dataset Identifier: &nbsp;
                {selectedDataset["Unique Dataset Identifier"]}
              </Typography>
              <Typography>
                Paper Title: &nbsp;
                {selectedDataset["Paper Title"] || (
                  <small> Not available</small>
                )}
              </Typography>
              <Typography>
                Dataset URL:{" "}
                <Link
                  sx={{ display: "inline" }}
                  href={selectedDataset["Dataset URL"]}
                >
                  {" "}
                  {selectedDataset["Dataset URL"]}
                </Link>
              </Typography>
              <Typography>
                Hugging Face URL:{" "}
                <Link
                  sx={{ display: "inline" }}
                  href={selectedDataset["Hugging Face URL"]}
                >
                  {selectedDataset["Hugging Face URL"]}
                </Link>
              </Typography>
            </Box>

            <Box
              sx={{
                display: "grid",
                gap: "10px",
              }}
            >
              <Typography
                variant="h5"
                component="h4"
                sx={{
                  fontWeight: "bold",
                }}
              >
                Dataset Characteristics
              </Typography>
              <Typography sx={{ fontWeight: "bold" }}>Format:</Typography>
              <ul>
                {selectedDataset.Format.map((data: string[], index: number) => (
                  // todo: more robust key
                  <li key={index}>{data}</li>
                ))}
              </ul>
              <Typography sx={{ fontWeight: "bold" }}>Languages:</Typography>
              <ul>
                {selectedDataset.Languages.map(
                  (data: string, index: number) => (
                    <li key={index}>{data}</li>
                  ),
                )}
              </ul>
              <Typography fontWeight={"bold"}>Task Categories:</Typography>
              <ul>
                {selectedDataset["Task Categories"].map(
                  (data: string, index: number) => (
                    <li key={index}>{data}</li>
                  ),
                )}
              </ul>
              <Typography fontWeight={"bold"}>Text Topics:</Typography>
              <ul>
                {selectedDataset["Inferred Metadata"]["Text Topics"].map(
                  (data: string, index: number) => (
                    <li key={index}>{data}</li>
                  ),
                )}
              </ul>
            </Box>

            <Box
              sx={{
                display: "grid",
                gap: "10px",
              }}
            >
              <Typography
                variant="h5"
                component="h4"
                sx={{
                  fontWeight: "bold",
                }}
              >
                Data Statistics
              </Typography>
              <Typography>
                Num Exs:
                {selectedDataset["Text Metrics"]["Num Dialogs"].toFixed(1)}
              </Typography>
              <Typography>
                Input Length (characters) [Minimum | Mean | Maximum]:{" "}
                {selectedDataset["Text Metrics"]["Min Inputs Length"].toFixed(
                  1,
                )}{" "}
                |{" "}
                {selectedDataset["Text Metrics"]["Mean Inputs Length"].toFixed(
                  1,
                )}{" "}
                |
                {selectedDataset["Text Metrics"]["Max Inputs Length"].toFixed(
                  1,
                )}
              </Typography>
              <Typography>
                Target Length (characters) [Minimum | Mean | Maximum]:{" "}
                {selectedDataset["Text Metrics"]["Min Targets Length"].toFixed(
                  1,
                )}{" "}
                |{" "}
                {selectedDataset["Text Metrics"]["Mean Targets Length"].toFixed(
                  1,
                )}{" "}
                |
                {selectedDataset["Text Metrics"]["Max Targets Length"].toFixed(
                  1,
                )}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "grid",
                gap: "10px",
              }}
            >
              <Typography
                variant="h5"
                component="h4"
                sx={{
                  fontWeight: "bold",
                }}
              >
                Data Provenance
              </Typography>
              <Typography fontWeight={"bold"}>Creators:</Typography>
              <ul>
                {selectedDataset.Creators?.length ? (
                  (selectedDataset.Creators as any).map(
                    (data: any, index: number) => <li key={index}>{data}</li>,
                  )
                ) : (
                  <small>No data available</small>
                )}
              </ul>
              <Typography fontWeight={"bold"}>Text Sources:</Typography>
              <ul>
                {selectedDataset["Text Sources"]?.length ? (
                  (selectedDataset["Text Sources"] as any).map(
                    (data: any, index: number) => <li key={index}>{data}</li>,
                  )
                ) : (
                  <small>No data available</small>
                )}
              </ul>
              <Typography fontWeight={"bold"}>Licenses:</Typography>
              <ul>
                {selectedDataset.Licenses?.length ? (
                  (selectedDataset.Licenses as any).map(
                    (data: any, index: number) => (
                      <li key={index}>{data.License}</li>
                    ),
                  )
                ) : (
                  <small>No data available</small>
                )}
              </ul>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default InspectIndividualDatasets;
