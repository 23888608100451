import { MAX_WIDTH } from "../constants";

/**
 * Converts a size value in vw to a CSS calc() expression that dynamically adjusts
 * based on the screen width. If the screen width is less than 1728px, the value in vw is used.
 * If the screen width is 1728px or greater, the equivalent pixel value is used.
 *
 * @param {number} size - The size value in vw.
 * @returns {string} A CSS calc() expression that adjusts based on the screen width.
 *
 * @example
 * // Returns "calc(10vw * (100vw / max(100vw, 1728px)))"
 * convertToViewportUnits(10);
 */

export default function convertToViewportUnits(size: number) {
  const maxValue = (MAX_WIDTH / 100) * size;

  return `min(${maxValue}px, ${size}vw)`;
}
