import * as Plot from "@observablehq/plot";

export default function createLanguageWorldMap(
  counts,
  countries,
  countrymesh,
  title,
  countryToLanguageMapping,
) {
  // Create a map with country names as keys and their respective values
  const countryValueMap = new Map(counts.map((d) => [d.name, d.value]));

  return Plot.plot({
    projection: "equal-earth",
    width: 928,
    height: 928 / 2,
    color: { scheme: "YlOrRd", unknown: "#ccc", label: title, legend: true },
    marks: [
      Plot.sphere({ fill: "white", stroke: "currentColor" }),
      Plot.geo(countries, {
        fill: (d) => countryValueMap.get(d.properties.name),
        title: (d) => {
          const countryName = d.properties.name;
          const value = countryValueMap.get(countryName);
          let languageStr = "";

          const languageMapping = countryToLanguageMapping[countryName];
          if (languageMapping) {
            for (const [lang, proportion] of Object.entries(languageMapping)) {
              if (proportion >= 0.05) {
                // 10% threshold
                languageStr += `${lang} (${(proportion * 100).toFixed(0)}%), `;
              }
            }
            // Removing trailing comma and space
            languageStr = languageStr.slice(0, -2);
          }

          return `Country: ${countryName}\n${languageStr ? `Spoken Languages: ${languageStr}` : ""}`;
        },
      }),
      Plot.geo(countrymesh, { stroke: "white" }),
    ],
  });
}
