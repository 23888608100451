import { SxProps, Typography } from "@mui/material";

export default function TextSummary({
  children,
  sx = {},
}: {
  children: any;
  sx?: SxProps;
}) {
  return (
    <Typography
      sx={{
        my: 2.5,
        width: { md: "50vw" },
        // maxWidth: "1000px",
        mx: "auto",
        textAlign: "center",
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
}
