import { Typography, ListItem, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

interface ContributorCardProps {
  item: {
    name: string;
    affiliate_title: string;
    country_code: string;
    profile_img_url?: string;
    personal_website?: string | null;
    affiliate?: string;
  };
}

export default function ContributorCard({ item }: ContributorCardProps) {
  const theme = useTheme();
  return (
    <ListItem
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: 0,
        width: "fit-content",
        "*": {
          fontSize: { md: "1.25rem", xs: "1rem" },
        },
      }}
    >
      <Typography variant="h4" style={{ margin: 0, fontWeight: 600 }}>
        {item.personal_website ? (
          <Link
            to={item.personal_website.includes('\/\/') ? item.personal_website : `\/\/${item.personal_website}`}
            target="_blank"
            style={{
              color: theme.customColors.primary.prusianBlue,
            }}
          >
            {item.name} ({item.country_code})
          </Link>
        ) : (
          <>
            {item.name} ({item.country_code})
          </>
        )}
      </Typography>
      {/* <Typography variant="h4" style={{ margin: 0, fontWeight: 500 }}>
        {item.affiliate_title}
      </Typography> */}
      <Typography
        variant="h4"
        style={{
          margin: 0,
          fontWeight: 500,
        }}
      >
        {item.affiliate}
      </Typography>
    </ListItem>
  );
}
