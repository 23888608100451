export default function computeMetrics(filteredData, allConstants) {
  //  Datasets with the same Dataset Name may have different languages
  //  but not different tasks, topics, or licenses. Let's not double count these.
  //  Drop duplicate rows based on 'Dataset Name' column

  let filteredDataUnique = removeDuplicatesByKey(filteredData, "Dataset Name");

  const sourceToDomain = Object.fromEntries(
    Object.entries(allConstants["DOMAIN_GROUPS"]).flatMap(([k, vs]) =>
      vs.map((v) => [v, k]),
    ),
  );

  const datasetsCount = countOccurrences(
    filteredData.map((row) => row["Unique Dataset Identifier"]),
  );
  const collectionsCount = countOccurrences(
    filteredData.map((row) => row["Collection"]),
  );

  const dialogsCount = filteredData.reduce(
    (acc, row) => acc + (row["Text Metrics"]?.["Num Dialogs"] || 0),
    0,
  );
  const languageCounts = countOccurrences(
    filteredData.flatMap((row) =>
      Array.isArray(row["Languages"]) ? row["Languages"] : [],
    ),
  );
  const taskcatCounts = countOccurrences(
    filteredDataUnique.flatMap((row) =>
      Array.isArray(row["Task Categories"]) ? row["Task Categories"] : [],
    ),
  );

  const topicsCount = {};

  for (const row of filteredData) {
    const textTopics = row["Inferred Metadata"]["Text Topics"] || [];
    for (const topic of textTopics) {
      if (!topicsCount[topic]) {
        topicsCount[topic] = 0;
      }
      topicsCount[topic]++;
    }
  }

  const sourcesCount = countOccurrences(
    filteredData.flatMap((row) =>
      Array.isArray(row["Text Sources"]) ? row["Text Sources"] : [],
    ),
  );
  const domainsCount = countOccurrences(
    filteredData.flatMap((row) =>
      Array.isArray(row["Text Sources"])
        ? row["Text Sources"].map((source) => sourceToDomain[source])
        : [],
    ),
  );

  const syntheticPct = getSyntheticPercentage(filteredData);

  const formatCounts = countOccurrences(
    filteredDataUnique.flatMap((row) =>
      Array.isArray(row["Format"]) ? row["Format"] : [],
    ),
  );
  const licenseCounts = countOccurrences(
    filteredDataUnique.flatMap((row) =>
      Array.isArray(row["Licenses"])
        ? row["Licenses"].map((licenseInfo) => licenseInfo["License"])
        : [],
    ),
  );

  return {
    collections: collectionsCount,
    datasets: datasetsCount,
    dialogs: dialogsCount,
    languages: languageCounts,
    task_categories: taskcatCounts,
    topics: topicsCount,
    sources: sourcesCount,
    domains: domainsCount,
    synthetic_pct: syntheticPct,
    licenses: licenseCounts,
    formats: formatCounts,
  };
}

function getSyntheticPercentage(df) {
  // Count the number of truthy values (where row is truthy)
  const numModels = df.reduce(
    (count, row) =>
      count + (row["Model Generated"] && row["Model Generated"].length ? 1 : 0),
    0,
  );

  // Calculate the synthetic percentage
  const syntheticPct = numModels
    ? Math.round(((100 * numModels) / df.length) * 10) / 10
    : 0.0;

  return syntheticPct;
}

/**
 * Counts the occurrences of each element in an array.
 *
 * @param {Array} arr - The array to count occurrences in.
 * @returns {Object} An object where the keys are the elements of the array and the values are their respective counts.
 *
 * @example
 *
 * countOccurrences(['a', 'b', 'a', 'c', 'b', 'a']);
 * // Returns: { a: 3, b: 2, c: 1 }
 */
function countOccurrences(arr) {
  return arr.reduce((acc, item) => {
    acc[item] = (acc[item] || 0) + 1;
    return acc;
  }, {});
}

/**
 * Removes duplicate objects from an array based on a specified key.
 *
 * @param {Array} arr - The array of objects to deduplicate.
 * @param {string} key - The key to check for duplicates.
 * @returns {Array} A new array with duplicates removed based on the specified key.
 *
 * @example
 *
 * const data = [
 *   { name: 'A', value: 1 },
 *   { name: 'B', value: 2 },
 *   { name: 'A', value: 3 },
 *   { name: 'C', value: 4 },
 *   { name: 'B', value: 5 },
 *   { name: 'D', value: 6 }
 * ];
 *
 * const uniqueData = removeDuplicatesByKey(data, 'name');
 * // Returns: [
 * //   { name: 'A', value: 1 },
 * //   { name: 'B', value: 2 },
 * //   { name: 'C', value: 4 },
 * //   { name: 'D', value: 6 }
 * // ]
 */
function removeDuplicatesByKey(arr, key) {
  const seen = new Set();
  return arr.filter((item) => {
    const value = item[key];
    if (seen.has(value)) {
      return false;
    } else {
      seen.add(value);
      return true;
    }
  });
}
