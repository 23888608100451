import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const data = [
  {
    title: "Dataset Explorer",
    description: "A tool to explore and filter 1800+ text finetuning datasets for their licenses, sources, creators, and more.",
    link: "/data-provenance-explorer/dataset-explorer",
    background: "#A1DDFF",
  },
  {
    title: "Web Data Explorer",
    description: "An explorer of the trends in data-use preferences from the websites commonly used to source AI data.",
    link: "/data-provenance-explorer/web-data-explorer",
    background: "#FAE4D4",
  },
];

const DataProvenanceExplorer = () => {
  return (
    <Box sx={{ mx: "auto", my: "110px" }}>
      <Stack
        alignItems="center"
        sx={{
          "&": { textAlign: "center", mx: "auto" },
          mx: "auto",
          mb: "105px",
          gap: "35px",
        }}
      >
        <Typography
          sx={{
            fontSize: { md: "96px", xs: "48px", sm: "64px" },
            fontWeight: 500,
            maxWidth: "1134px",
            lineHeight: { md: "116.18px" },
          }}
        >
          Data Provenance Explorer
        </Typography>
        <Typography
          sx={{
            fontSize: { md: "36px", xs: "24px" },
            fontWeight: 500,
            maxWidth: "920px",
          }}
        >
          Explorer tools for popular AI Datasets (left), and the common Websites from which they are derived (right) {" "}
        </Typography>
      </Stack>
      <Stack flexDirection={{ xs: "column", md: "row" }} sx={{ gap: "77px" }}>
        {data.map((d) => (
          <Box
            key={d.title}
            sx={{
              width: { xl: "627px", sm: "400px", xs: "300px", lg: "425px" },
              height: { xl: "766px" },
              aspectRatio: 0.8185,
              borderRadius: "15px",
              mx: "auto",
            }}
          >
            <Link
              to={d.link}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "100%",
                height: "100%",
                borderRadius: "15px",
                background: d.background,
                padding: "44px 49px",
                color: "black",
              }}
            >
              <Typography sx={{ fontWeight: 600, fontSize: "36px" }}>
                {d.title}
              </Typography>
              <Typography sx={{ fontWeight: 400, fontSize: "24px" }}>
                {d.description}
              </Typography>
            </Link>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default DataProvenanceExplorer;
