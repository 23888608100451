export default function setupLangWorldMap(
  countryCodes,
  langCodes,
  countryCodeToLangCodes,
) {
  const langMap = {};
  for (const code in langCodes) {
    langMap[code] = langCodes[code].split(";").map((lang) => lang.trim());
  }

  // Create the desired output mapping from country name to their languages and percentages
  const countryToLanguageMapping = {};

  for (let country of countryCodes) {
    const { code, name } = country;

    const languageDataForCountry = countryCodeToLangCodes[code];
    if (!languageDataForCountry) continue; // Skip countries without language data

    countryToLanguageMapping[name] = {};

    for (let langCode in languageDataForCountry) {
      const { percent } = languageDataForCountry[langCode];
      const languageNames = langMap[langCode];

      if (languageNames) {
        for (let langName of languageNames) {
          countryToLanguageMapping[name][langName] = percent / 100.0;
        }
      }
    }
  }

  // Step 1: Map language to countries present for easy lookup
  const languageToCountryMapping = {};
  for (let country in countryToLanguageMapping) {
    for (let lang in countryToLanguageMapping[country]) {
      if (lang in countryToLanguageMapping[country] > 0.1) {
        if (!languageToCountryMapping[lang]) {
          languageToCountryMapping[lang] = [];
        }
        languageToCountryMapping[lang].push(country);
      }
    }
  }
  return languageToCountryMapping;
}
