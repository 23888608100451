export default function fillNa(dataSummary) {
  return dataSummary.map((row) => {
    const filledRow = {};
    for (const key in row) {
      if (Object.hasOwnProperty.call(row, key)) {
        filledRow[key] = row[key] == null ? "" : row[key];
      }
    }
    return filledRow;
  });
}
