import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from "@mui/material";

import { CodeStyled } from "./Form";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const BulletListItem = styled("li")({
  listStyleType: "circle",
});

const Instructions = () => {
  return (
    <Accordion
      sx={{
        boxShadow: "0",
        borderTop: "2px solid #aaa",
        borderBottom: "2px solid #aaa",
        // padding: "20px",
      }}
    >
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: 600,
          }}
        >
          Instructions
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <Stack
          component={"ol"}
          gap={"10px"}
          sx={{
            // p: 0,
            // display: "grid",
            // gap: "16px",
            fontSize: 20,
            fontWeight: 500,
            lineHeight: "30px",
          }}
        >
          <Typography variant="h5" component={"li"}>
            <strong>Select from the licensed data use cases.</strong>The options
            range from least to most strict: <CodeStyled>Commercial</CodeStyled>
            , <CodeStyled>Unspecified</CodeStyled>,{" "}
            <CodeStyled>Non-Commercial</CodeStyled>,{" "}
            <CodeStyled>Academic-Only</CodeStyled>.
            <Typography
              variant="h5"
              component={"ul"}
              sx={{
                display: "grid",
                padding: 0,
                gap: "10px",
              }}
            >
              <Typography variant="h5" component={"li"}>
                <CodeStyled>Commercial </CodeStyled> will select only the data
                with licenses explicitly permitting commercial use.
              </Typography>
              <Typography variant="h5" component={"li"}>
                <CodeStyled>Unspecified </CodeStyled> includes Commercial plus
                datasets with no license found attached, which may suggest the
                curator does not prohibit commercial use.
              </Typography>
              <Typography variant="h5" component={"li"}>
                <CodeStyled>Non-Commercial</CodeStyled> includes Commercial and
                Unspecified datasets plus those licensed for non-commercial use.
              </Typography>
              <Typography variant="h5" component={"li"}>
                <CodeStyled>Academic-Only</CodeStyled> will select all available
                datasets, including those that restrict to only academic uses.
              </Typography>
            </Typography>
            Note that these categories reflect the self-reported licenses
            attached to datasets, and assume fair use of any data they are
            derived from (e.g. scraped from the web).
          </Typography>
          <Typography variant="h5" component={"li"}>
            Select whether to include datasets with Attribution requirements in
            their licenses.
          </Typography>
          <Typography variant="h5" component={"li"}>
            Select whether to include datasets with{" "}
            <CodeStyled>Share-Alike</CodeStyled> requirements in their licenses.
            Share-Alike means a copyright left license, that allows others to
            re-use, re-mix, and modify works, but requires that derivative work
            is distributed under the same terms and conditions.
          </Typography>
          <Typography variant="h5" component={"li"}>
            Select whether to ignore the{" "}
            <Link
              to="https://openai.com/policies/terms-of-use/"
              target="_blank"
              style={{ color: "rgb(46, 154, 255)" }}
            >
              OpenAI Terms of Use
            </Link>{" "}
            as a Non-Commercial restriction, and include datasets that are at
            least partially generated by OpenAI (inputs, outputs, or both).
            While the OpenAI terms state you cannot 'use output from the
            Services to develop models that compete with OpenAI,' there is
            debate as to their enforceability and applicability to third parties
            who did not generate this data themselves. See our Legal Discussion
            section in the paper for more discussion on these terms.
          </Typography>
          <Typography variant="h5" component={"li"}>
            <strong>Select Language Families</strong> to include.
          </Typography>
          <Typography variant="h5" component={"li"}>
            <strong>Select Task Categories</strong> to include.
          </Typography>
          <Typography variant="h5" component={"li"}>
            <strong>Select Time of Collection.</strong> By default, it includes
            all datasets.
          </Typography>
          <Typography variant="h5" component={"li"}>
            <strong>Select the Text Domains</strong> to include.
          </Typography>
        </Stack>
        <Typography
          variant="h5"
          sx={{
            lineHeight: "30px",
            marginBottom: "50px",
          }}
        >
          Finally, Submit Selection when ready!
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default Instructions;
