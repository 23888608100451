import axios from "axios";
import JSZip from "jszip";
import memoizee from "memoizee";

import {
  LICENSE_CONSTANTS_URL,
  CREATOR_CONSTANTS_URL,
  CUSTOM_LICENSE_CONSTANTS_URL,
  DOMAINS_CONSTANTS_URL,
  FORMATS_CONSTANTS_URL,
  LANGUAGE_CONSTANTS_URL,
  MODEL_CONSTANTS_URL,
  TASK_CONSTANTS_URL,
} from "./constants";
import DatasetInfo from "../types/IDatasetInfo";

// Constants similar to `constants` module in the Python code
const CONSTANTS = {
  LICENSE_CONSTANTS_URL,
  CUSTOM_LICENSE_CONSTANTS_URL,
  LANGUAGE_CONSTANTS_URL,
  TASK_CONSTANTS_URL,
  DOMAINS_CONSTANTS_URL,
  MODEL_CONSTANTS_URL,
  CREATOR_CONSTANTS_URL,
  FORMATS_CONSTANTS_URL,
};

// Function to fetch and extract data summary JSON from a ZIP file on the server
const readDataSummaryJson = memoizee(async function (
  summaryDirUrl = "/data_summaries.zip",
) {
  try {
    // Fetch the ZIP file from the server
    const response = await axios.get(summaryDirUrl, {
      responseType: "arraybuffer",
    });
    const zip = await JSZip.loadAsync(response.data);

    // Extract the 'data_summaries' folder
    const dataSummariesFolder = zip.folder("data_summaries");
    if (!dataSummariesFolder) {
      throw new Error("Folder 'data_summaries' not found in the ZIP file");
    }

    const filePromises = [] as Promise<any>[];
    dataSummariesFolder.forEach((relativePath, file) => {
      // Read each file in the 'data_summaries' folder
      if (
        !relativePath.includes("_template.json") &&
        !relativePath.includes("_template_spec.yaml")
      ) {
        filePromises.push(
          file.async("string").then((content) => ({
            relativePath,
            content: JSON.parse(content),
          })),
        );
      }
    });

    const files = await Promise.all(filePromises);
    const dataSummary: DatasetInfo[] = [];

    files.forEach((file) => {
      dataSummary.push(...extractValuesAsArray(file.content));
    });

    return dataSummary;
  } catch (error) {
    console.error("Error fetching or processing data summary:", error);
    throw error;
  }
});

function extractValuesAsArray(obj: any) {
  const keys = Object.keys(obj);

  return keys.map((key) => obj[key]);
}

// Function to read all constants from server
export const readAllConstants = memoizee(async function () {
  try {
    const [
      licenseClasses,
      customLicenseClasses,
      languageGroups,
      taskGroups,
      source_groups,
      modelGroups,
      creatorGroups,
      formats,
    ] = await Promise.all([
      axios.get(CONSTANTS.LICENSE_CONSTANTS_URL),
      axios.get(CONSTANTS.CUSTOM_LICENSE_CONSTANTS_URL),
      axios.get(CONSTANTS.LANGUAGE_CONSTANTS_URL),
      axios.get(CONSTANTS.TASK_CONSTANTS_URL),
      axios.get(CONSTANTS.DOMAINS_CONSTANTS_URL),
      axios.get(CONSTANTS.MODEL_CONSTANTS_URL),
      axios.get(CONSTANTS.CREATOR_CONSTANTS_URL),
      axios.get(CONSTANTS.FORMATS_CONSTANTS_URL),
    ]);

    return {
      LICENSE_CLASSES: licenseClasses.data,
      CUSTOM_LICENSE_CLASSES: customLicenseClasses.data,
      LANGUAGE_GROUPS: languageGroups.data,
      TASK_GROUPS: taskGroups.data,
      DOMAIN_GROUPS: source_groups.data,
      MODEL_GROUPS: modelGroups.data,
      CREATOR_GROUPS: creatorGroups.data,
      FORMATS: formats.data,
    };
  } catch (error) {
    console.error("Error fetching constants:", error);
    return {};
  }
});

export { readDataSummaryJson };
