import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Divider,
} from "@mui/material";
import Metrics from "../Metrics";
import getMetricsForDisplay from "../../utils/getMetricsForDisplay";
import { useExplorerData } from "../../hooks/useExplorerData";
import Titlebar from "./components/Titlebar";
import TextSummary from "./components/TextSummary";

type DataSummary = {
  [key: string]: any[];
  Collection: string[];
};

const DataCollectionsSummary = ({
  dataSummary,
}: {
  dataSummary: DataSummary | null;
}) => {
  const [activeSortColumn, setActiveSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [defaultSort, setDefaultSort] = useState<boolean>(true); // Track default sorting
  const [sortedData, setSortedData] = useState<DataSummary | null>(null);

  useEffect(() => {
    if (dataSummary) {
      handleSort(Object.keys(dataSummary)[0]); // Set default sorting to first column ascending
    }
  }, [dataSummary]);

  useEffect(() => {
    if (dataSummary && activeSortColumn !== null) {
      const sorted = getSortedData(dataSummary, sortDirection);
      setSortedData(sorted);
    }
  }, [dataSummary, activeSortColumn, sortDirection]);

  const handleSort = (column: string | null) => {
    setActiveSortColumn(column);
    // If default sort and not descending, switch to ascending
    if (defaultSort && sortDirection !== "desc") {
      setSortDirection("asc");
      setDefaultSort(false);
    } else {
      // Cycle through "asc" and "desc"
      setSortDirection((prevDirection) =>
        prevDirection === "asc" ? "desc" : "asc",
      );
    }
  };

  const getSortedData = (
    data: DataSummary,
    direction: "asc" | "desc",
  ): DataSummary => {
    const sortedData = { ...data };
    const sortedIndex = [...data.Collection].map((_, index) => index);

    sortedIndex.sort((a, b) => {
      const aValue = sortedData[activeSortColumn as keyof DataSummary][a];
      const bValue = sortedData[activeSortColumn as keyof DataSummary][b];
      if (direction === "asc") {
        if (typeof aValue === "string" && typeof bValue === "string") {
          return aValue.localeCompare(bValue);
        } else {
          return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
        }
      } else {
        if (typeof aValue === "string" && typeof bValue === "string") {
          return bValue.localeCompare(aValue);
        } else {
          return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
        }
      }
    });

    for (const key in sortedData) {
      sortedData[key] = sortedIndex.map(
        (index) => sortedData[key as keyof DataSummary][index],
      );
    }
    return sortedData;
  };

  const { metrics, metaData } = useExplorerData();

  const stats = useMemo(() => {
    return getMetricsForDisplay(metrics, metaData);
  }, [metrics, metaData]);
  return (
    <Box
      sx={{
        display: "grid",
        gap: "30px",
        marginTop: "30px",
      }}
    >
      <Box>
        <Titlebar>General Properties of your collection</Titlebar>
        <TextSummary sx={{ my: 1 }}>
          Given your selection, see the quantity of data (collections, datasets,
          dialogs), the characteristics of the data (languages, tasks, topics),
          and the sources of data covered (sources, domains, % synthetically
          generated by models).
        </TextSummary>
      </Box>
      <Box sx={{ my: 3 }}>
        <Metrics metrics={stats} />
      </Box>
      <Divider sx={{ my: 2 }} />
      <Titlebar sx={{ textAlign: { md: "start", xs: "center" } }}>
        Summary of Data Collections
      </Titlebar>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {dataSummary &&
                Object.keys(dataSummary).map((key) => (
                  <TableCell
                    key={key}
                    sx={{
                      cursor: "pointer",
                      fontWeight: activeSortColumn === key ? "bold" : "normal",
                    }}
                    onClick={() => handleSort(key)}
                  >
                    <TableSortLabel
                      active={activeSortColumn === key}
                      direction={
                        activeSortColumn === key
                          ? sortDirection === "asc"
                            ? "asc"
                            : "desc"
                          : undefined
                      }
                    >
                      {key}
                    </TableSortLabel>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData &&
              sortedData.Collection.map((_, index) => (
                <TableRow key={index}>
                  {Object.keys(sortedData).map((key) => (
                    <TableCell key={key}>
                      {sortedData[key as keyof DataSummary][index]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DataCollectionsSummary;
