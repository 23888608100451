import { Box, Stack, Typography } from "@mui/material";
import React from "react";

export type Graph = {
  title: string;
  description: string;
  image: string;
};

const WebDataGraph = ({ graph }: { graph: Graph }) => {
  return (
    <Box
      sx={{
        width: {
          xl: "1305px",
          lg: "1100px",
          md: "850px",
          sm: "580px",
          xs: "350px",
        },
        mt: "30px",
        "& ": { textAlign: "center" },
      }}
    >
      <Stack
        sx={{ gap: { md: "29px", xs: "24px" }, mb: { md: "85px", xs: "52px" } }}
      >
        <Typography sx={{ fontSize: { md: "48px", xs: "42px" } }}>
          {graph.title}
        </Typography>
        {graph.description != "" ? <Typography
          sx={{
            fontSize: { md: "1.5rem", xs: "1rem" },
            maxWidth: "1017px",
            mx: "auto",
          }}
        >
          {graph.description}
        </Typography> : null}
      </Stack>
      <Box component="img" src={graph.image} sx={{ width: "100%" }} />
    </Box>
  );
};

export default WebDataGraph;
