import React from "react";

const DiscordIcon: React.FC = () => (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    className="discord-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 620 471"
  >
    <defs>
      <style>
        {`.cls-1 {
          stroke-width: 0px;
        }`}
      </style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <g id="dLNoGI.tif">
        <path
          className="cls-1"
          d="M461,471h-1c-1.36-2.09-2.64-4.23-4.09-6.26-9.79-13.72-18.68-28-26.52-42.92-2.96-5.63-2.86-5.73,3.14-8.16,14.23-5.75,28.12-12.2,41.5-19.75,3.29-1.86,3.95-3.11.8-6.15-5.28-5.08-10.05-5.4-16.86-2.35-39.79,17.8-81.61,27.97-125.09,30.48-59.07,3.41-115.85-7.01-170.08-30.7-6.18-2.7-10.65-2.84-15.57,2.01-4.13,4.07-4.44,4.21.83,7.13,12.99,7.2,26.35,13.59,40.15,19.08,6.45,2.56,6.45,2.66,3.26,8.91-8.74,17.12-19.52,32.95-30.48,48.68h-1c-2.16-.84-4.29-1.82-6.5-2.52-51.18-16.22-98.48-40.16-142.41-71-5.9-4.14-8.21-8.79-8.34-15.66-.16-8.28-1.14-16.55-1.76-24.82,0-13,0-26,0-39,.26-1.63.72-3.26.76-4.9.3-12.17,1.51-24.26,2.92-36.33C14.64,191.76,46.09,114.94,93.23,44.08c1.42-2.14,3.15-3.78,5.53-4.85C137.11,22.07,176.95,9.74,218.27,2.19c3.82-.7,6.39.21,8.15,3.8,3.58,7.33,7.71,14.42,10.88,21.92,2.18,5.15,5.11,6.39,10.43,5.56,18.45-2.89,37.09-3.96,55.73-4.3,23.84-.44,47.6,1.08,71.21,4.47,4.45.64,7.08-.51,8.96-4.84,3.24-7.48,7.26-14.61,10.65-22.03,2.06-4.5,5.04-5.26,9.66-4.4,40.49,7.49,79.52,19.58,117.18,36.22,3.2,1.41,5.47,3.53,7.39,6.4,17.15,25.68,32.47,52.37,45.32,80.46,22.98,50.25,37.8,102.75,43.45,157.77.95,9.26,1.81,18.52,2.72,27.78v54c-.62,6.94-1.47,13.86-1.78,20.81-.18,4.2-1.65,7.07-5.13,9.54-31.01,22.06-63.78,40.93-98.81,55.9-17.45,7.46-35.4,13.52-53.29,19.75ZM468.25,254.89c.23-4.69-1.14-13.38-4.8-21.58-8.76-19.61-22.92-32.87-44.96-35.01-22.27-2.16-39.51,7.43-50.56,26.47-14.39,24.77-13.43,49.73,3.04,73.37,17.44,25.03,50.46,29.89,74.31,10.99,15.96-12.65,22.79-29.85,22.98-54.24ZM263.01,259.19c.23-9.68-1.68-18.89-5.57-27.73-16.06-36.48-61.47-44.96-87.8-16.38-23.87,25.91-21.83,68.81,4.39,92.3,19.57,17.53,48.31,17.65,67.9.19,14.45-12.88,20.76-29.35,21.08-48.39Z"
        />
      </g>
    </g>
  </svg>
);

export default DiscordIcon;
