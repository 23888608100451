import { Box, SxProps, Stack } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import homeBg from "../images/topo lines.svg";
import { MAX_WIDTH } from "../constants";
import { useTheme } from "@mui/material";
import { ExplorerFooter } from "./Footer";

const BackgroundProvider = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();
  const [path, setPath] = useState<string>("/");
  const location = useLocation();

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  const content = (
    <Stack className="content-wrapper" sx={{ minHeight: "100vh" }}>
      <Box
        sx={{
          mx: "auto",
          width: "100%",
          alignSelf: "stretch",
          backgroundColor:
            path === "/data-provenance-explorer/dataset-explorer" ||
            path === "/data-provenance-explorer" ||
            path === "/data-provenance-explorer/web-data-explorer"
              ? theme.customColors.primary.prusianBlue
              : "inherit",
        }}
      >
        {" "}
        <Navbar
          sx={{
            mx: "auto",
            width: `min(${MAX_WIDTH}px, 100%)`,
          }}
        />
      </Box>

      <Stack
        className="content"
        sx={{
          px: "2vw",
          width: `min(${MAX_WIDTH}px, 100%)`,
          mx: "auto",
          flex: "1",
          alignSelf: "stretch",
        }}
        direction={"column"}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            width: "100%",
            alignSelf: "center",
            mx: "auto",
          }}
        >
          {children}
        </Box>
      </Stack>
      {path !== "/data-provenance-explorer/dataset-explorer" &&
      path !== "/data-provenance-explorer/web-data-explorer" &&
      path !== "/data-provenance-explorer" ? (
        <Box
          sx={{
            marginTop: "auto",
            px: "2vw",
            backgroundColor: theme.customColors.primary.prusianBlue,
          }}
        >
          <Footer />
        </Box>
      ) : (
        <Box
          sx={{
            marginTop: "auto",
          }}
        >
          <ExplorerFooter />
        </Box>
      )}
    </Stack>
  );

  const commonStyles: SxProps = {
    width: "min(100%, 100vw)",
    minHeight: "100vh",
    padding: "0 !important",
    // padding: "20px",
  };

  if (path === "/") {
    return (
      <Box
        sx={{
          ...commonStyles,
          backgroundColor: theme.customColors.primary.prusianBlue,
          color: theme.customColors.primary.aliceBlue,
          backgroundImage: `url('${homeBg}')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {content}
      </Box>
    );
  } else if (
    path === "/data-provenance-explorer/dataset-explorer" ||
    path === "/data-provenance-explorer/web-data-explorer" ||
    path === "/data-provenance-explorer"
  ) {
    return (
      <Box
        sx={{
          ...commonStyles,
          backgroundColor: "#fff",
          color: theme.customColors.primary.prusianBlue,
        }}
      >
        {content}
      </Box>
    );
  } else if (path === "/explorer-introduction") {
    return (
      <Box
        sx={{
          ...commonStyles,
          backgroundColor: theme.customColors.primary.prusianBlue,
          color: theme.customColors.primary.aliceBlue,
        }}
      >
        {content}
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          ...commonStyles,
          backgroundColor: theme.customColors.primary.aliceBlue,
          color: theme.customColors.primary.prusianBlue,
        }}
      >
        {content}
      </Box>
    );
  }
};

export default BackgroundProvider;
