import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  FC,
  useEffect,
} from "react";
import { readAllConstants } from "../utils/io";
import loadData from "../utils/loadData";
import { applyFilters } from "../utils/filter";
import { format } from "date-fns";

interface DataType {
  date: number[];
  useCase: string;
  selectedLanguages: string[];
  selectedCategories: string[];
  selectedDomainTypes: string[];
  attribution: boolean;
  shareAlike: boolean;
  openAIGeneratedData: boolean;
  // false initially, true forever once user has submitted selection
  shouldShowData: boolean;
}

interface DataContextType {
  data: DataType;
  setData: React.Dispatch<React.SetStateAction<DataType>>;
}

const DataContext = createContext<DataContextType | undefined>(undefined);

interface DataProviderProps {
  children: ReactNode;
}
const dates = { startDate: "1999-12-18", endDate: "2023-12-01" };

const DataProvider: FC<DataProviderProps> = ({ children }) => {
  // todo - sync formdata start values with this data
  const [data, setData] = useState<DataType>({
    date: [
      new Date(dates.startDate).getTime(),
      new Date(dates.endDate).getTime(),
    ],
    useCase: "Academic-Only",
    selectedLanguages: ["All"],
    selectedCategories: ["All"],
    selectedDomainTypes: ["All"],
    attribution: true,
    shareAlike: true,
    openAIGeneratedData: false,
    shouldShowData: false,
  });

  useEffect(() => {
    if (data.shouldShowData) {
      const selectedLicenses = data.useCase;
      const openAiLicenseOverride = data.openAIGeneratedData;
      const licenseAttribution = data.shareAlike ? "1" : "0";
      const licenseSharerAlike = data.attribution ? "1" : "0";
      const languagesMultiselect = data.selectedLanguages;
      const taskcats_multiselect = data.selectedCategories;
      const domain_multiselect = data.selectedDomainTypes;
      const startTime = format(new Date(data.date[0]), "yyyy-MM-dd");
      const endTime = format(new Date(data.date[1]), "yyyy-MM-dd");

      //   const startTime = "1999-12-18";
      //   const endTime = "2023-12-01";
    }
  }, [
    data.useCase,
    data.attribution,
    data.date,
    data.openAIGeneratedData,
    data.selectedCategories,
    data.shareAlike,
    data.shouldShowData,
    data.selectedLanguages,
    data.selectedDomainTypes,
  ]);

  return (
    <DataContext.Provider value={{ data, setData }}>
      {children}
    </DataContext.Provider>
  );
};

const useFilterData = (): DataContextType => {
  const context = useContext(DataContext);

  if (!context) {
    throw new Error("useFilterData must be used within a DataProvider");
  }

  return context;
};

export { DataProvider, useFilterData };
