import fillNa from "./fillNa";
import { mapLicenseCriteria } from "./filter";
import { readAllConstants, readDataSummaryJson } from "./io";

export default async function loadData() {
  let dataSummary = await readDataSummaryJson();

  dataSummary = mapLicenseCriteria(dataSummary, await readAllConstants());
  return fillNa(dataSummary);
}
