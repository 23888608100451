import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  FC,
  useEffect,
} from "react";
import { readAllConstants } from "../utils/io";
import loadData from "../utils/loadData";
import { applyFilters } from "../utils/filter";
import { format } from "date-fns";
import { useFilterData } from "./useFilterData";
import computeMetrics from "../utils/computeMetrics";
import DatasetInfo from "../types/IDatasetInfo";
interface DataType {
  data: null | DatasetInfo[];
  constants: any;
  filteredData: null | DatasetInfo[];
  metrics: any;
  metaData: any;
}

const initialState: DataType = {
  data: null,
  filteredData: null,
  constants: null,
  metrics: null,
  metaData: null,
};

const DataContext = createContext<DataType>(initialState);

interface DataProviderProps {
  children: ReactNode;
}

const ExplorerDataProvider: FC<DataProviderProps> = ({ children }) => {
  // todo - sync formdata start values with this data
  const filters = useFilterData().data;

  const [explorerData, setExplorerData] = useState<DataType>(initialState);

  useEffect(() => {
    async function anon() {
      const constants = await readAllConstants();
      const dataSummary = await loadData();

      const metaData = computeMetrics(dataSummary, constants);

      setExplorerData((d) => ({
        ...d,
        constants,
        data: dataSummary,
        filteredData: null,
        metaData,
      }));
    }
    anon();
  }, []);

  useEffect(() => {
    if (filters.shouldShowData) {
      const selectedLicenses = filters.useCase;
      const openAiLicenseOverride = filters.openAIGeneratedData;
      const licenseAttribution = filters.shareAlike ? "1" : "0";
      const licenseSharerAlike = filters.attribution ? "1" : "0";
      const languagesMultiselect = filters.selectedLanguages;
      const taskcats_multiselect = filters.selectedCategories;
      const domain_multiselect = filters.selectedDomainTypes;
      const startTime = format(new Date(filters.date[0]), "yyyy-MM-dd");
      const endTime = format(new Date(filters.date[1]), "yyyy-MM-dd");

      const filteredData = applyFilters(
        explorerData.data,
        explorerData.constants,
        null,
        null,
        selectedLicenses,
        openAiLicenseOverride,
        licenseAttribution,
        licenseSharerAlike,
        languagesMultiselect,
        taskcats_multiselect,
        domain_multiselect,
        // todo: better way than null
        startTime === "1999-12-18" ? null : startTime,
        endTime === "2023-12-01" ? null : endTime,
      );

      const metrics = computeMetrics(filteredData, explorerData.constants);

      setExplorerData((data) => ({
        ...data,
        filteredData,
        metrics,
      }));
    }
  }, [filters, explorerData.data, explorerData.constants]);

  return (
    <DataContext.Provider value={explorerData}>{children}</DataContext.Provider>
  );
};

const useExplorerData = (): DataType => {
  const context = useContext(DataContext);

  if (!context) {
    throw new Error("useFilterData must be used within a DataProvider");
  }

  return context;
};

export { ExplorerDataProvider, useExplorerData };
