import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import DataCollectionsSummary from "./DataCollectionsSummary";
import GlobalRepresentation from "./GlobalRepresentation";
import { DataSummary } from "../../pages/DatasetExplorer";
import InspectIndividualDatasets from "./InspectIndividualDatasets";
import TextCharacteristics from "./TextCharacteristics";
import DataLicenses from "./DataLicenses";
import { SxProps, useMediaQuery, useTheme } from "@mui/material";

export default function ChartViewer({
  dataSummary,
}: {
  dataSummary: DataSummary | null;
}) {
  const [activeTab, setActiveTab] = React.useState(0);
  const largeScreen = useMediaQuery("(min-width:750px)");
  const theme = useTheme();

  const tabStyles: SxProps = {
    fontSize: 20,
    fontWeight: 600,
    color: theme.customColors.supplementary.grey,
  };
  return (
    <Box sx={{ width: "100%", mb: 8 }}>
      <Box sx={{ maxWidth: "100%", overflow: "auto" }}>
        <Tabs
          centered={largeScreen}
          variant={!largeScreen ? "scrollable" : undefined}
          scrollButtons="auto"
          value={activeTab}
          onChange={(e, val) => setActiveTab(val)}
          aria-label="basic tabs example"
          allowScrollButtonsMobile
          indicatorColor="primary"
        >
          <Tab label="Data Summary" sx={{ ...tabStyles }} />
          <Tab label="Global Representation" sx={{ ...tabStyles }} />
          <Tab label="Text Characteristics" sx={{ ...tabStyles }} />
          <Tab label="Data Licenses" sx={{ ...tabStyles }} />
          <Tab label="Inspect Individual Datasets" sx={{ ...tabStyles }} />
        </Tabs>
      </Box>
      <Box sx={{ mt: 5 }}>
        {activeTab === 0 && (
          <DataCollectionsSummary dataSummary={dataSummary} />
        )}

        {activeTab === 1 && <GlobalRepresentation />}

        {activeTab === 4 && <InspectIndividualDatasets />}
        {activeTab === 2 && <TextCharacteristics />}
        {activeTab === 3 && <DataLicenses />}
      </Box>
    </Box>
  );
}
