import { ThemeOptions, createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface Theme {
    customColors: {
      primary: {
        prusianBlue: string;
        aliceBlue: string;
        lion: string;
        pistachio: string;
        madder: string;
      };
      supplementary: {
        light: string;
        grey: string;
        bluish: string;
        darkBlue: string;
      };
    };
  }
}

const theme = createTheme({
  typography: {
    fontFamily: "Inter, open-sans",
    // fontSize: 20,

    button: {
      textTransform: "none",
    },
    h4: {
      fontWeight: 500,
    },
    body1: {
      fontWeight: 500,
    },
  },
  palette: {
    primary: {
      main: "#081f30",
    },
    error: {
      main: "#a31621",
    },
  },

  customColors: {
    primary: {
      prusianBlue: "#081f30",
      aliceBlue: "#def3ff",
      lion: "#b89976",
      pistachio: "#abdf75",
      madder: "#a31621",
    },
    supplementary: {
      light: "#fffbf8",
      grey: "#aeaeae",
      bluish: "#a1ddff",
      darkBlue: "#577f9b",
    },
  },
} as ThemeOptions);

theme.typography.h1 = {
  fontSize: "40px",
  fontWeight: 500,

  [theme.breakpoints.up("sm")]: {
    fontSize: "55px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "70px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "80px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "96px",
  },
};

theme.typography.h2 = {
  fontWeight: 500,

  fontSize: "32px",
  [theme.breakpoints.up("sm")]: {
    fontSize: "40px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "48px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "56px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "64px",
  },
};

theme.typography.h3 = {
  fontWeight: 500,

  fontSize: "24px",
  [theme.breakpoints.up("sm")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "36px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "36px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "36px",
  },
};

theme.typography.h4 = {
  fontSize: "20px",
  fontWeight: 500,

  [theme.breakpoints.up("sm")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "24px",
  },
};

theme.typography.h5 = {
  fontSize: "16px",
  fontWeight: 500,
  [theme.breakpoints.up("sm")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "20px",
  },
};

export default theme;
