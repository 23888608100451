import React, { useMemo, useState } from "react";
import useAxios from "../../hooks/useAxios";
import languageMap from "../../charts/languageMap";
import { Box, Stack } from "@mui/material";
import creatorMap from "../../charts/creatorMap";
import creatorSunburst from "../../charts/creatorSunburst";
import { useExplorerData } from "../../hooks/useExplorerData";
import LoadingAnimation from "../LoadingAnimation";
import VisualizationContainer from "./VisualizationContainer";
import Titlebar from "./components/Titlebar";
import TextSummary from "./components/TextSummary";
import createMapPlaceholder from "./utils/createMapPlaceholder";
import SkeletonLoader from "./components/SkeletonLoader";

export default function GlobalRepresentation() {
  const explorerData = useExplorerData();

  const [urls] = useState([
    "/html_constants/countries-50m.json",
    "/html_constants/country-codes.json",
    "/html_constants/language-codes.json",
    "/html_constants/country-code-to-language-codes.json",
    "/constants/creator_groups_by_country.json",
    "/constants/creator_groups.json",
  ]);

  const worldRes = useAxios({ method: "get", url: urls[0] });
  const countryCodeRes = useAxios({ method: "get", url: urls[1] });
  const langCodesRes = useAxios({ method: "get", url: urls[2] });
  const countryCodeToLangCodesRes = useAxios({ method: "get", url: urls[3] });
  const countryToCreatorRes = useAxios({ method: "get", url: urls[4] });
  const creatorGroupsRes = useAxios({ method: "get", url: urls[5] });

  const langRepresentationReadyToAdd = [
    worldRes,
    langCodesRes,
    countryCodeRes,
    countryCodeToLangCodesRes,
  ].every((item) => {
    return !item.loading && item.response;
  });

  const languageRepresentationMap = useMemo(() => {
    if (langRepresentationReadyToAdd) {
      return languageMap(
        worldRes.response,
        langCodesRes.response,
        countryCodeRes.response,
        countryCodeToLangCodesRes.response,
        explorerData.filteredData || [],
      ) as HTMLElement;
    } else {
      return createMapPlaceholder();
    }
  }, [
    langRepresentationReadyToAdd,
    worldRes,
    countryCodeRes,
    langCodesRes,
    countryCodeToLangCodesRes,
    explorerData.filteredData,
  ]);

  const creatorRepresentationMapReadyToAdd = [
    worldRes,
    countryToCreatorRes,
  ].every((item) => {
    return !item.loading && item.response;
  });

  const creatorRepresentationMap = useMemo(() => {
    if (creatorRepresentationMapReadyToAdd) {
      const filteredData = explorerData.filteredData || [];

      return creatorMap(
        worldRes.response,
        countryToCreatorRes.response,
        filteredData,
      ) as HTMLElement;
    }
    return createMapPlaceholder();
  }, [
    creatorRepresentationMapReadyToAdd,
    worldRes,
    countryToCreatorRes,
    explorerData.filteredData,
  ]);

  const creatorPropertiesReadyToAdd = [creatorGroupsRes].every((item) => {
    return !item.loading && item.response;
  });

  const creatorPropertiesMap = useMemo(() => {
    if (!creatorPropertiesReadyToAdd) return createMapPlaceholder();

    const filteredData = explorerData.filteredData || [];
    // todo: fix zoom not working
    const elem = creatorSunburst(
      creatorGroupsRes.response,
      filteredData,
    )! as unknown as HTMLElement;
    return elem;
  }, [
    creatorGroupsRes.response,
    creatorPropertiesReadyToAdd,
    explorerData.filteredData,
  ]);

  return (
    <Box>
      <TextSummary>
        This section explores the representation of text datasets
        internationally. These datasets contain a wide distribution of
        languages, and are created by many organizations and institutions. We
        measure both the representation across countries in which these
        languages are spoken, as well as "who creates these datasets"?
      </TextSummary>

      <Stack gap={{ xs: 3, md: 5 }}>
        {/* <LoadingAnimation loading={!langRepresentationReadyToAdd}> */}
        <LoadingAnimation loading={false}>
          <Box>
            <Box>
              <Titlebar>Language Representation</Titlebar>
              <TextSummary>
                First we visualize the language coverage per country, according
                to the spoken languages and their representation in the Data
                Provenance Collection. We compute a score{" "}
                <em>
                  S
                  <sub>
                    <em>k</em>
                  </sub>
                </em>{" "}
                for each country <em>k</em>, parametrized by{" "}
                <em>
                  p
                  <sub>
                    <em>kl</em>
                  </sub>
                </em>
                , the percentage of people in country <em>k</em> that speak
                language <em>l</em>, and{" "}
                <em>
                  w
                  <sub>
                    <em>li</em>
                  </sub>
                </em>{" "}
                which is a binary indicator that is 1 if dataset <em>i</em>{" "}
                &isin; <em>D</em> contains language <em>l</em> and 0 otherwise.
              </TextSummary>
              <TextSummary>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <em>
                    S
                    <sub>
                      <em>k</em>
                    </sub>
                  </em>{" "}
                  ={" "}
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      fontSize: "30px",
                      alignSelf: "flex-end",
                    }}
                  >
                    &sum;
                    <sub style={{ fontSize: "16px" }}>
                      <em>l</em>&isin;<em>L</em>
                    </sub>
                  </span>{" "}
                  <span style={{ fontSize: "60px", fontWeight: 200 }}>(</span>
                  <em>
                    p
                    <sub>
                      <em>kl</em>
                    </sub>
                  </em>{" "}
                  <span
                    style={{
                      fontSize: "25px",
                      marginRight: "5px",
                      marginLeft: "5px",
                    }}
                  >
                    &times;
                  </span>{" "}
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      fontSize: "30px",
                      alignSelf: "flex-end",
                    }}
                  >
                    &sum;
                    <sub style={{ fontSize: "16px" }}>
                      <em>l</em>&isin;<em>L</em>
                    </sub>
                  </span>{" "}
                  <em>
                    w
                    <sub>
                      <em>li</em>
                    </sub>
                  </em>
                  <span style={{ fontSize: "60px", fontWeight: 200 }}>)</span>
                </Box>
              </TextSummary>
              {langRepresentationReadyToAdd ? (
                <VisualizationContainer
                  content={languageRepresentationMap}
                ></VisualizationContainer>
              ) : (
                <SkeletonLoader />
              )}
            </Box>
            <TextSummary>
              NB: While many global south countries have large English speaking
              populations, it may still not mean they are well represented by
              English text from Western/European origins.
            </TextSummary>
          </Box>
        </LoadingAnimation>

        <Box>
          <Titlebar>Dataset Creator Representation by Country</Titlebar>
          <TextSummary>
            Here we visualize the density of organizations that package/create
            these datasets for machine learning, in contrast to the above.
          </TextSummary>
          <TextSummary>This may help answer 'who owns the data?'</TextSummary>
          {creatorRepresentationMapReadyToAdd ? (
            <VisualizationContainer
              content={creatorRepresentationMap}
            ></VisualizationContainer>
          ) : (
            <SkeletonLoader />
          )}{" "}
        </Box>
        <Box>
          <Titlebar>Dataset Creator Properties</Titlebar>
          <TextSummary>
            Here we count the contributions of organizations to dataset
            creation.
          </TextSummary>
          {creatorPropertiesReadyToAdd ? (
            <VisualizationContainer
              content={creatorPropertiesMap}
            ></VisualizationContainer>
          ) : (
            <SkeletonLoader />
          )}
        </Box>
      </Stack>
    </Box>
  );
}
