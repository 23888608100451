import { useState, useEffect } from "react";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import equal from "fast-deep-equal";

interface UseAxiosReturn<T> {
  response: T | null;
  error: AxiosError | null;
  loading: boolean;
}

function useAxios<T>(axiosConfig: AxiosRequestConfig): UseAxiosReturn<T> {
  const [response, setResponse] = useState<T | null>(null);
  const [error, setError] = useState<AxiosError | null>(null);
  const [loading, setLoading] = useState(true);
  const [prevConfig, setPrevConfig] = useState<AxiosRequestConfig | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res: AxiosResponse<T> = await axios(axiosConfig);
        setResponse(res.data);
      } catch (err) {
        setError(err as AxiosError);
      } finally {
        setLoading(false);
      }
    };

    if (!prevConfig || !equal(prevConfig, axiosConfig)) {
      setLoading(true);
      setPrevConfig(axiosConfig);
      fetchData();
    }
  }, [axiosConfig, prevConfig]);

  return { response, error, loading };
}

export default useAxios;
