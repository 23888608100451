import { Typography, List, Box, ListItem, Stack } from "@mui/material";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material";
import convertToViewportUnits from "../helpers/convertToViewportUnits";
import publicationsData from "../data/publications.json";

export const ListItemStyled = styled(ListItem)({
  margin: 0,
  paddingLeft: 0,
  borderBottom: "1px solid #081F30",
  marginBottom: "10px",
});

const Publications = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        px: convertToViewportUnits(5),
        pb: convertToViewportUnits(8),
        pt: convertToViewportUnits(2),
        gap: "40px",
        marginTop: { md: "50px" },
      }}
    >
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "64px",
          [theme.breakpoints.down("md")]: {
            fontSize: "40px",
          },
        }}
      >
        Publications
      </Typography>

      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 5,
        }}
      >
        {publicationsData.map((item, index) => (
          <ListItemStyled
            key={index}
            sx={{
              marginBottom: "30px",
              paddingBottom: "20px",
              "*": {
                // fontWeight: 500,
                // wordBreak: "break-word",
                transition: "0.25s",
              },
              ".link-item:hover": {
                "*": {
                  color: `${theme.palette.error.main} !important`,
                },
              },
              lineHeight: "29.05px",
            }}
          >
            <Stack style={{ gap: 20 }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 500,
                  color: theme.customColors.supplementary.darkBlue,
                }}
              >
                {item.authors.reduce((acc, author) => {
                  const formattedName = `${author.first_name.charAt(0).toUpperCase()}. ${author.last_name.toUpperCase()}`;
                  return acc ? `${acc}, ${formattedName}` : formattedName;
                }, "")}
              </Typography>
              <Link
                to={item.url}
                style={{
                  color: theme.customColors.primary.prusianBlue,
                  display: "flex",
                  flexDirection: "column",
                  gap: 20,
                }}
                className="link-item"
                target="_blank"
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "36px",
                    lineHeight: "43.57px",
                  }}
                >
                  {item.title}{" "}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1.5rem",
                    fontWeight: 500,
                  }}
                >
                  {item.venue}
                </Typography>
              </Link>
            </Stack>
          </ListItemStyled>
        ))}
      </List>
    </Box>
  );
};

export default Publications;
