import {
  Typography,
  ListItem,
  Box,
  useTheme,
  Stack,
  Grid,
} from "@mui/material";
import { Link } from "react-router-dom";
import convertToViewportUnits from "../../helpers/convertToViewportUnits";
import Person2Icon from "@mui/icons-material/Person2";
import contributorsData from "../../data/contributors.json";
import ProfileCard from "./components/ProfileCard";
import ContributorCard from "./components/ContributorCard";

const { leads, advisors, primary_contributors, general_contributors } =
  contributorsData;

console.log(general_contributors);

const Contributors = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "40px",
        px: convertToViewportUnits(5),
        pb: convertToViewportUnits(8),
        pt: convertToViewportUnits(2),
        marginTop: { md: "50px" },
      }}
    >
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "64px",
          [theme.breakpoints.down("md")]: {
            fontSize: "40px",
          },
        }}
      >
        Our Team
      </Typography>

      <Stack gap={5}>
        <Box
          sx={{
            borderTop: `1px solid ${theme.customColors.primary.prusianBlue}`,
            paddingTop: "30px",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: 700,
              marginBottom: "40px",
            }}
          >
            Leads
          </Typography>
          <Box sx={{ width: "100%", overflow: "auto" }}>
            <Grid
              spacing={{ xs: 4, md: 7 }}
              container
              sx={{
                // gap: { xs: 3, md: 9 },
                justifyContent: "start",
              }}
            >
              {leads.map((item, index) => (
                <Grid item xs={6} sm={4} lg={3}>
                  <ProfileCard
                    item={{ ...item }}
                    key={index}
                  ></ProfileCard>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            borderTop: `1px solid ${theme.customColors.primary.prusianBlue}`,
            paddingTop: "30px",
            mt: 10,
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: 700,
              marginBottom: "40px",
            }}
          >
            Advisors
          </Typography>
          <Box sx={{ overflow: "auto", width: "100%" }}>
            <Grid
              spacing={{ xs: 4, md: 7 }}
              container
              sx={{
                // gap: { xs: 3, md: 9 },
                justifyContent: "start",
              }}
            >
              {advisors.map((item, index) => (
                <Grid item xs={6} sm={4} lg={3}>
                  <ProfileCard item={{ ...item }} key={index}></ProfileCard>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            borderTop: `1px solid ${theme.customColors.primary.prusianBlue}`,
            paddingTop: "30px",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: 700,
              marginBottom: "40px",
            }}
          >
            Contributors
          </Typography>
          <Box sx={{ overflow: "auto", width: "100%" }}>
            <Grid container spacing={{ xs: 3, md: 7 }} sx={{}}>
              {[...primary_contributors, ...general_contributors].map(
                (item, index) => (
                  <Grid xs={6} sm={4} lg={3} key={index} item>
                    <ContributorCard item={item} />
                  </Grid>
                ),
              )}
            </Grid>
          </Box>
        </Box>
        {/* <Box
          sx={{
            borderTop: `1px solid ${theme.customColors.primary.prusianBlue}`,
            paddingTop: "30px",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: 700,
              marginBottom: "40px",
            }}
          >
            Primary Contributors
          </Typography>
          <Box sx={{ overflow: "auto", width: "100%" }}>
            <Stack
              direction={"row"}
              flexWrap={"wrap"}
              width={"100%"}
              sx={{
                gap: { xs: 3, md: 9 },
                justifyContent: "flex-start",

                img: {
                  width: { xs: "200px", sm: "250", md: "314px" },
                },
              }}
            >
              {primary_contributors.map((item, index) => (
                <ListItem
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: 0,
                    width: "fit-content",
                  }}
                >
                  <Typography
                    variant="h4"
                    style={{ margin: 0, fontWeight: 600 }}
                  >
                    {item.personal_website ? <Link
                      to={item.personal_website}
                      target="_blank"
                      style={{
                        color: theme.customColors.primary.prusianBlue,
                      }}
                    >
                      {item.name} ({item.country_code})
                    </Link> : <>{item.name} ({item.country_code})</>}
                  </Typography>
                  <Typography
                    variant="h4"
                    style={{ margin: 0, fontWeight: 500 }}
                  >
                    {item.affiliate_title}
                  </Typography>
                  <Typography
                    variant="h4"
                    style={{
                      margin: 0,
                      fontWeight: 500,
                      fontStyle: "italic",
                    }}
                  >
                    {item.affiliate}
                  </Typography>
                </ListItem>
              ))}
            </Stack>
          </Box>
        </Box> */}
      </Stack>
    </Box>
  );
};

export default Contributors;
