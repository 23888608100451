import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { DATA_REPO_LINK } from "../constants";

const DataRepository = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        padding: "10vw",
        gap: "70px",
        maxWidth: "1428px",
        margin: "0 auto",
        [theme.breakpoints.down("md")]: {
          flexDirection: "column-reverse",
        },
      }}
    >
      <Stack
        sx={{
          flex: 1,
          alignItems: "start",
          gap: "20px",
          justifyContent: "space-evenly",
          [theme.breakpoints.down("md")]: {
            alignItems: "center",
          },
        }}
      >
        <Typography
          sx={{
            marginBottom: "40px",
            fontSize: 96,
            [theme.breakpoints.down("md")]: {
              fontSize: 60,
            },
          }}
        >
          Data Repository
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "16px", md: "20px" },
          }}
        >
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cumque cum
          eum delectus iure inventore soluta consectetur harum doloremque odit
          totam, eos animi, ab ea atque, odio asperiores quae fugit
          exercitationem. Maxime vel id numquam obcaecati sint. Eligendi laborum
          quasi voluptate dignissimos aperiam nemo explicabo itaque neque
          placeat quibusdam, voluptas asperiores.
        </Typography>
        <Button
          style={{
            color: theme.palette.primary.dark,
          }}
          endIcon={<ArrowRightAltIcon />}
        >
          <Link
            to={DATA_REPO_LINK}
            target="_blank"
            style={{
              textDecoration: "none",
              color: theme.palette.primary.dark,
              fontWeight: "bold",
              fontSize: "25px",
              fontStyle: "italic",
            }}
          >
            Visit Repository
          </Link>
        </Button>
      </Stack>

      <figure
        style={{ flex: 1, backgroundColor: "#fff", borderRadius: "20px" }}
      >
        <img src="" alt="" style={{ width: "100%", aspectRatio: 1 }} />
        <figcaption>figcaption</figcaption>
      </figure>
    </Box>
  );
};

export default DataRepository;
