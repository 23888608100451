import { Box, Stack, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import convertToViewportUnits from "../helpers/convertToViewportUnits";
import { DATA_REPO_LINK } from "../constants";
import React, { useEffect } from "react";
import { useRef } from "react";

const ua = window.navigator.userAgent;
const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);


function FrameWrapper() {
  const ref = useRef();
  const [height, setHeight] = React.useState("0px");
  const [width, setWidth] = React.useState("0px");

  const onLoad = () => {
    setHeight(`${window.innerHeight * .8}px`);
    setWidth(window.innerWidth + "px");
  };
  return (
    <iframe
      // ref={ref}
      onLoad={onLoad}
      id="myFrame"
      src="/Consent_in_Crisis.pdf"
      width={width}
      height={height}
      scrolling="no"
      frameBorder="0"
      style={{
        width: "100%",
        // overflow: "auto",
      }}
    ></iframe>
  );
}

const WebPaper = () => {
  const theme = useTheme();

  return (
    <div className="x" style={{
      width: "100%",
      height: "100%",
    }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          px: convertToViewportUnits(5),
          pb: convertToViewportUnits(8),
          pt: convertToViewportUnits(2),
          gap: "40px",
          marginTop: { md: "50px" },
        }}
      ><Typography
        sx={{
          fontWeight: 500,
          fontSize: "64px",
          [theme.breakpoints.down("md")]: {
            fontSize: "40px",
          },
        }}
      >Consent in Crisis: The Rapid Decline of the AI Data Commons
        </Typography>
        <a href="/Consent_in_Crisis.pdf" style={{
          color: "#fff",
          textDecoration: "none",
          fontSize: "1rem",
          padding: "20px 0",
          backgroundColor: theme.customColors.primary.madder,
          borderRadius: "15px",
          textAlign: "center",
          maxWidth: "200px"
        }}>View PDF</a>
      </Box>

      <div className="foo" style={{
        width: "100%",
        height: "100%",
      }}>
        <FrameWrapper />
      </div>

      {/* {iOS ? <div style={{
        backgroundImage: "/Consent_In_Crisis.png",
        backgroundSize: "contain",
        width: "100%",
        height: "100%",
      }} /> : <div className="foo" style={{
        width: "100%",
        height: "100%",
      }}>
        <FrameWrapper />
      </div>} */}
    </div>
  );
};

export default WebPaper;
