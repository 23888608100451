import {
  Box,
  Button,
  Link,
  List,
  styled,
  Typography,
  useTheme,
} from "@mui/material";

import { ListItemStyled } from "./Publications";
import convertToViewportUnits from "../helpers/convertToViewportUnits";

import pressData from "../data/press.json";

const pressList = pressData;

const LinkStyled = styled(Link)(({ theme }) => ({
  ":hover": {
    color: `${theme.palette.error.main} !important`,
    backgroundColor: "rgba(0,0,0,0)",
  },
  textDecoration: "none",
  transition: "0.25s",
  display: "block",
  fontSize: "1.5rem",
}));

const Press = () => {
  const theme = useTheme();
  const spotlightPress =
    pressList.find((item) => item.is_spotlight) || pressList[0];

  return (
    <Box
      sx={{
        padding: convertToViewportUnits(5),
        alignSelf: "stretch",
        gap: "70px",
        width: "100%",
        maxWidth: "100%",
        // width: "min(1500px, 100%)",
        // margin: "0 auto",
      }}
    >
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "64px",
          [theme.breakpoints.down("md")]: {
            fontSize: "40px",
          },
        }}
      >
        Press
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "40px",
          justifyItems: "start",
          marginTop: "40px",
          alignItems: "center",
          width: "100%",
          [theme.breakpoints.down("md")]: {
            gap: "50px",
          },
        }}
      >
        <Box
          component="a"
          href={spotlightPress.url}
          target="_blank"
          sx={{
            // flex: 1,
            margin: 0,
            padding: 0,
            position: "relative",
            width: "100%",
            height: { xs: 250, sm: 300, md: 450, lg: 587 },
            borderRadius: "15px",
            overflow: "hidden",
            backgroundImage: `url(${spotlightPress.hero})`, //`url('${require("../images/wapo-dpi.jpeg")}')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Typography
            sx={{
              position: "absolute",
              top: "30px",
              left: "30px",
              color: theme.customColors.primary.aliceBlue,
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "19.36px",
              [theme.breakpoints.down("md")]: {
                width: "100%",
                left: "10px",
                top: "10px",
                fontSize: "10px",
              },
            }}
          >
            {spotlightPress.publication_date} | {spotlightPress.publication}
          </Typography>
          <Typography
            variant="h3"
            sx={{
              position: "absolute",
              bottom: { xs: "10px", sm: "20px", md: "30px" },
              left: "30px",
              color: theme.customColors.primary.aliceBlue,
              width: "50%",
              fontWeight: 600,
              [theme.breakpoints.down("md")]: {
                width: "100%",
                left: 0,
                textAlign: "center",
              },
            }}
          >
            {spotlightPress.headline}
          </Typography>
        </Box>

        <List style={{ flex: 1, width: "100%" }}>
          {pressList.map((item, index) => (
            <ListItemStyled
              key={index}
              sx={{
                fontSize: "1.5rem",
                display: "inline-flex",
                py: 2,
                fontWeight: 500,
                lineHeight: "29px",
              }}
            >
              <LinkStyled
                target="_blank"
                href={item.url}
                style={{ color: theme.customColors.primary.prusianBlue }}
              >
                {item.headline} | {item.publication_date} | {item.publication}
              </LinkStyled>
            </ListItemStyled>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default Press;
