import { Box, Stack, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import convertToViewportUnits from "../helpers/convertToViewportUnits";
import { DATA_REPO_LINK } from "../constants";

const About = () => {
  const theme = useTheme();
  return (
    <Box
    sx={{
      width: "100%",
      display: "flex",
      flexDirection: "column",
      px: convertToViewportUnits(5),
      pb: convertToViewportUnits(8),
      pt: convertToViewportUnits(2),
      gap: "40px",
      marginTop: { md: "50px" },
    }}
    >
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "64px",
          [theme.breakpoints.down("md")]: {
            fontSize: "40px",
          },
        }}
      >About
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Typography sx={{ fontSize: { xs: "1.5rem", md: "1.75rem" } }}>
        The Data Provenance Initiative is a volunteer collective of AI researchers
        from around the world. We conduct large-scale audits of the massive datasets
        that power state-of-the-art AI models. We have audited over 4,000 popular text,
        speech, and video datasets, tracing them from origin to creation, cataloging
        data sources, licenses, creators, and other metadata, which researchers can
        examine using our Explorer tool. We recently analyzed 14,000 web domains, to
        understand the evolving provenance and consent signals behind AI data. The
        purpose of this work is to map the landscape of AI data, improving transparency,
        documentation, and informed use of data.
        </Typography>
        <Typography sx={{ fontSize: { xs: "1.5rem", md: "1.75rem" } }}>
          You can download this data (with filters) directly from the{" "}
          <Link
            to={DATA_REPO_LINK}
            style={{
              textDecoration: "underline",
              color: theme.customColors.primary.madder,
            }}
          >
            Data Provenance Collection.{" "}
          </Link>
        </Typography>
        <Typography sx={{ fontSize: { xs: "1.5rem", md: "1.75rem" } }}>
          If you wish to contribute or discuss, please feel free to contact the
          organizers at{" "}
          <Link
            to="mailto:data.provenance.init@gmail.com"
            style={{
              color: theme.customColors.primary.madder,
              wordBreak: "break-word",
              textDecoration: "underline",
            }}
          >
            data.provenance.init@gmail.com.
          </Link>
        </Typography>
        <Typography
          variant="h4"
          marginTop="30px"
        >
          Legal Notice: It is important to note we collect self-reported
          licenses, from the papers and repositories that released these
          datasets, and categorize them according to our best efforts, as a
          volunteer research and transparency initiative. The information
          provided by any of our works and any outputs of the Data Provenance
          Initiative do NOT, and are NOT intended to, constitute legal advice;
          instead, all information, content, and materials are for general
          informational purposes only.
        </Typography>
      </Box>
      <Stack
        gap={5}
        sx={{ flexDirection: { md: "row", xs: "column" }, marginTop: "125px" }}
      >
        <Link
          style={{
            color: "#fff",
            textDecoration: "none",
            fontSize: "36px",
            padding: "20px 0",
            backgroundColor: theme.customColors.primary.madder,
            borderRadius: "15px",
            textAlign: "center",
            flexGrow: 1,
          }}
          to="/publications"
        >
          Publications
        </Link>
        <Link
          style={{
            color: "#fff",
            textDecoration: "none",
            fontSize: "36px",
            padding: "20px 0",
            backgroundColor: theme.customColors.primary.madder,
            borderRadius: "15px",
            textAlign: "center",
            flexGrow: 1,
          }}
          to="/contributors"
        >
          Contributors
        </Link>
      </Stack>
    </Box>
  );
};

export default About;
