import { Box, useTheme } from "@mui/material";
import Form from "../components/Form";
import ChartViewer from "../components/ChartViewer/ChartViewer";
import { useEffect, useState } from "react";
import prepCollectionTable from "../utils/prepCollectionTable";
import { useExplorerData } from "../hooks/useExplorerData";
import Instructions from "../components/Instructions";

import DataExplorerHeader from "../components/DataExplorerHeader";
import BreadcrumbsComp from "../components/BreadcrumbsComp";
import { PAPER_LINK1 } from "../constants";

export interface DataSummary {
  [key: string]: any[];
  Collection: string[];
}

const DatasetExplorer = () => {
  const [dataSummary, setDataSummary] = useState<DataSummary | null>(null);

  const explorerData = useExplorerData();

  const { metrics, metaData } = explorerData;
  const theme = useTheme();

  useEffect(() => {
    if (explorerData.filteredData) {
      const metadeta = metaData;

      if (metrics && metadeta) {
        const prep_table = prepCollectionTable(
          explorerData.filteredData,
          explorerData.data,
          metrics,
        );
        setDataSummary(prep_table);
      }
    }
  }, [explorerData.filteredData, explorerData.data, metrics, metaData]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        gap: "50px",
        margin: "0 auto",
        // marginTop: { xs: "20px", sm: "30px", md: "60px" },
        color: theme.customColors.primary.prusianBlue,
      }}
    >
      {/* <Typography
        variant="h1"
        component="h1"
        sx={{
          pl: { lg: 8 },
          [theme.breakpoints.down("md")]: {
            fontSize: "40px",
          },
        }}
      >
        Data Provenance Explorer
      </Typography> */}
      <BreadcrumbsComp page="Dataset Explorer" />
      <DataExplorerHeader
        title="Dataset Explorer"
        description=''
        explorerPaper={PAPER_LINK1}
      />
      <Instructions />

      <Form />

      {explorerData.filteredData ? (
        <ChartViewer dataSummary={dataSummary} />
      ) : (
        <></>
      )}

      {/* <List>
          {list.map((item, index) => (
            <ListItemStyled key={index}>
              <Typography>{item.title}</Typography>
            </ListItemStyled>
          ))}
        </List> */}
    </Box>
  );
};

export default DatasetExplorer;
