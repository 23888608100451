type MetricComparison = [number, number];

export type MetricsComparison = {
  Collections: MetricComparison;
  Datasets: MetricComparison;
  Dialogs: MetricComparison;
  Languages: MetricComparison;
  "Task Categories": MetricComparison;
  Topics: MetricComparison;
  "Text Domains": MetricComparison;
  "Text Sources": MetricComparison;
  "Synthetic Text": number;
};

export default function getMetricsForDisplay(
  metrics: any,
  metaData: any,
): MetricsComparison {
  const metadeta = metaData;

  const stats: MetricsComparison = {
    Collections: [
      Object.keys(metrics["collections"]).length,
      Object.keys(metadeta["collections"]).length,
    ],
    Datasets: [
      Object.keys(metrics["datasets"]).length,
      Object.keys(metadeta["datasets"]).length,
    ],
    Dialogs: [metrics["dialogs"], metadeta["dialogs"]],
    Languages: [
      Object.keys(metrics["languages"]).length,
      Object.keys(metadeta["languages"]).length,
    ],
    "Task Categories": [
      Object.keys(metrics["task_categories"]).length,
      Object.keys(metadeta["task_categories"]).length,
    ],
    Topics: [
      Object.keys(metrics["topics"]).length,
      Object.keys(metadeta["topics"]).length,
    ],
    "Text Domains": [
      Object.keys(metrics["domains"]).length,
      Object.keys(metadeta["domains"]).length,
    ],
    "Text Sources": [
      Object.keys(metrics["sources"]).length,
      Object.keys(metadeta["sources"]).length,
    ],
    "Synthetic Text": metrics["synthetic_pct"],
  };

  return stats;
}
