import { Button, Stack } from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Link } from "react-router-dom";
import { DATA_REPO_LINK } from "../constants";

const PaperAndRepository = ({
  paper
}: {
  paper: string;
}) => {
  return (
    <Stack
      justifyContent={"center"}
      sx={{
        my: 1,
        "&>*": {
          width: 255,
          height: 73,
          fontSize: "1.5rem !important",
          borderRadius: "36px !important",
          // borderWidth: "3px",
          gap: 1,
        },
        flexDirection: { xs: "column", md: "row" },
      }}
      // direction={"row"}
      gap={3}
    >
      <Button
        target="_blank"
        LinkComponent={Link}
        {...({ to: paper } as any)}
        variant="contained"
      >
        Paper <ArrowOutwardIcon />{" "}
      </Button>
      <Button
        target="_blank"
        LinkComponent={Link}
        {...({ to: DATA_REPO_LINK } as any)}
        variant="outlined"
      >
        Repository <ArrowOutwardIcon />{" "}
      </Button>
    </Stack>
  );
};

export default PaperAndRepository;

<Stack></Stack>;
