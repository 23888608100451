import { Box, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import createBarChart from "../../charts/createBarChart";
import computeMetrics from "../../utils/computeMetrics";
import { useExplorerData } from "../../hooks/useExplorerData";
import Titlebar from "./components/Titlebar";

export default function DataLicenses() {
  const barRef = useRef<HTMLDivElement>(null);
  const explorerData = useExplorerData();
  useEffect(() => {
    if (!explorerData.filteredData) return;

    const metrics = computeMetrics(
      explorerData.filteredData,
      explorerData.constants,
    )!;

    const key = "licenses";
    const licenses = metrics[key];

    console.log(metrics);

    const container = barRef.current!;
    const chart = createBarChart(licenses);
    container.appendChild(chart);

    return () => {
      chart.remove();
    };
  }, [explorerData]);
  return (
    <Box sx={{ mx: "auto" }}>
      <Typography sx={{ width: { md: "50vw" } }}>
        This section explores the{" "}
        <span style={{ fontStyle: "italic" }}>self-reported</span> data licenses
        by the creators of each dataset.
      </Typography>
      <br />
      <Typography sx={{ width: { md: "50vw" } }}>
        Note a few important limitations:
      </Typography>

      <Box component="ul" sx={{ width: { md: "50vw" } }}>
        <li>
          {" "}
          The legal status of data licenses is not always clear and may be
          different by jurisdiction.{" "}
        </li>{" "}
        <li> Despite our best efforts, omissions or mistakes are possible. </li>
        <li>
          {" "}
          This transparency initiative is{" "}
          <strong style={{ fontWeight: "bold" }}>not</strong>&nbsp;intended as
          legal advice, and bears no responsibility on how the
          <span style={{ fontStyle: "italic" }}> self-reported </span> licenses
          are used.
        </li>
      </Box>

      <Titlebar sx={{ textAlign: "start" }}>Data Licenses</Titlebar>

      <Typography sx={{ my: 1, width: { md: "50vw" } }}>
        Here we see the license distribution of those collected by the Data
        Provenance Initiative
      </Typography>
      <Box ref={barRef}></Box>
    </Box>
  );
}
