import * as Plot from "@observablehq/plot";

export default function createWorldMap(counts, countries, countrymesh, title) {
  // Create a map with country names as keys and their respective values
  const countryValueMap = new Map(counts.map((d) => [d.name, d.value]));

  return Plot.plot({
    projection: "equal-earth",
    width: 928,
    height: 928 / 2,
    color: { scheme: "YlOrRd", unknown: "#ccc", label: title, legend: true },
    marks: [
      Plot.sphere({ fill: "white", stroke: "currentColor" }),
      Plot.geo(countries, {
        fill: (d) => countryValueMap.get(d.properties.name),
        title: (d) => {
          const value =
            (100 * countryValueMap.get(d.properties.name)).toFixed(2) / 100;
          return `Country: ${d.properties.name}\nValue: ${value}`;
        },
      }),
      Plot.geo(countrymesh, { stroke: "white" }),
    ],
  });
}
