import { Settings } from "react-slick";

export const sliderSettings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  arrows: false,
  autoplay: true,
  speed: 750,
  autoplaySpeed: 5000,
  cssEase: "ease-in-out",
} as Settings;
