export const LICENSE_CONSTANTS_URL = "/constants/license_classes.json";
export const CUSTOM_LICENSE_CONSTANTS_URL =
  "/constants/custom_license_classes.json";
export const LANGUAGE_CONSTANTS_URL = "/constants/language_families.json";
export const TASK_CONSTANTS_URL = "/constants/task_groups.json";
export const MODEL_CONSTANTS_URL = "/constants/model_groups.json";
export const CREATOR_CONSTANTS_URL = "/constants/creator_groups.json";
export const CREATOR_COUNTRY_CONSTANTS_URL =
  "/constants/creator_groups_by_country.json";
export const FORMATS_CONSTANTS_URL = "/constants/data_formats.json";
export const DOMAINS_CONSTANTS_URL = "/constants/domain_groups.json";

export const LICENSE_USE_TYPES = [
  "Commercial",
  "Unspecified",
  "Non-Commercial",
  "Academic-Only",
];
export const LICENSE_USE_CLASSES = [
  "Commercial",
  "Unspecified",
  "Non-Commercial",
  "Academic-Only",
];
