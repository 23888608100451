import { Link } from "react-router-dom";
import { Box, Stack, Button, Typography } from "@mui/material";
import Slider from "react-slick";
import { useTheme } from "@mui/material";
import convertToViewportUnits from "../helpers/convertToViewportUnits";
import { sliderSettings } from "../config/sliderSettings";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

// const instructionsList = [
//   { img: "", instruction: "Select from the licensed data use cases" },
//   {
//     img: "",
//     instruction:
//       "Select whether to include datasets with Attribution requirements in their licenses",
//   },
//   {
//     img: "",
//     instruction:
//       "Select whether to include datasets with Share-Alike requirements in their licenses",
//   },
//   {
//     img: "",
//     instruction:
//       "Select whether to ignore the OpenAI Terms of Use as a Non-Commercial restriction, and include datasets that are at least partially generated by OpenAI (inputs, outputs, or both)",
//   },
//   { img: "", instruction: "Select Language Families to include" },
//   { img: "", instruction: "Select Task Categories to include" },
//   {
//     img: "",
//     instruction:
//       "Select Time of Collection. By default, it includes all datasets",
//   },
//   { img: "", instruction: "Select the Text Domains to include" },
// ];

const ExplorerIntroduction = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: { xs: "center", md: "space-between" },
        px: convertToViewportUnits(3),
        pb: convertToViewportUnits(10),
        color: theme.customColors.primary.aliceBlue,
        gap: { xs: "70px", lg: 0 },
        [theme.breakpoints.down("lg")]: {
          flexDirection: "column-reverse",
        },
      }}
    >
      <Box
        sx={{
          width: { md: "100%", sm: 541 },
          flex: { md: 1 },
          display: "flex",
          flexDirection: "column",
          gap: { xs: "20px", md: "30px", lg: "40px" },
          alignItems: "start",
          marginTop: "40px",
          [theme.breakpoints.down("lg")]: {
            width: "100%",
            gap: "20px",
            px: "20px",
          },
        }}
      >
        <Typography sx={{ lineHeight: { md: "116px" } }} variant="h1">
          The Data Provenance Explorer
        </Typography>
        <Box
          sx={{
            width: { lg: "60%" },
            [theme.breakpoints.down("lg")]: {
              width: "auto",
            },
          }}
        >
          <Typography variant="h4">
            Explore the datasets included in our audit. The DPI Explorer tool
            allows you to filter for and analyze language model training
            datasets.
          </Typography>
        </Box>

        <Button
          variant="contained"
          color="error"
          LinkComponent={Link}
          className="link-item"
          // href="/data-provenance-explorer"
          {...({ to: "/data-provenance-explorer" } as any)}
          size="large"
          sx={{
            fontSize: "1.5rem",
            borderRadius: "36px",
            height: 73,
            width: 255,
            gap: 1,
            mt: {
              lg: 5,
            },
          }}
        >
          <span className="link-text">Try Now</span>

          <ArrowOutwardIcon />
        </Button>
      </Box>
      <Box
        sx={{
          width: { xs: `min(90vw, 370px)`, sm: 541, md: 550, lg: 575, xl: 688 },
          maxWidth: 688,
          aspectRatio: { xs: 1.24 },
          marginTop: "20px",
          flex: { md: 1 },
          borderRadius: "15px",
          ".slick-dots": {
            "*::before": {
              fontSize: { xs: "9.5px", md: "12px" },
              color: `${theme.customColors.primary.aliceBlue} !important`,
            },
          },
        }}
      >
        <Box
          sx={{
            pb: 3,
            width: "100%",
            pl: { lg: 2, xs: 1 },
            pr: { lg: 0, xs: 1 },
          }}
        >
          <Stack
            sx={{
              display: "inline-flex !important",
              width: "100%",
              height: '100%',
              borderRadius: "15px",
              overflow: "hidden",
              // background: "red",
              justifyContent: "flex-end",
              aspectRatio: {
                xs: 1.24,
              },
              // background: `url(${require('../images/explorer_step1.png')})`,
              // backgroundPosition: '50% 50%',
              // backgroundRepeat: "no-repeat",
              // backgroundSize: "contain",
            }}
          >
            <Box
              sx={{
                background: `url(${require('../images/explorer_step1.png')})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: '50% 50%',
                backgroundColor: '#FFFBF8',
                aspectRatio: {
                  xs: 1.24,
                },
              }}
            ></Box>
            <Typography
              sx={{
                backgroundColor: "#192C39",
                px: { xs: "44px", md: "56px" },
                py: { xs: "2rem", md: "56px" },
                // flexGrow: 1,
                justifySelf: "flex-end",
                fontSize: { md: "1.5rem", xs: "1rem" },
              }}
            >
              {"Select and filter use cases, languages, and more"}
            </Typography>
          </Stack>
        </Box>

        {/* <Slider {...sliderSettings}>
          {instructionsList.map((instruction, index) => (
            <Box
              sx={{
                pb: 3,
                width: "100%",
                pl: { lg: 2, xs: 1 },
                pr: { lg: 0, xs: 1 },
              }}
              key={index}
            >
              <Stack
                sx={{
                  display: "inline-flex !important",
                  width: "100%",
                  borderRadius: "15px",
                  overflow: "hidden",
                  // background: "red",
                  justifyContent: "flex-end",
                  aspectRatio: {
                    xs: 1.24,
                  },
                  background: "url('/explorer-slider-bg.png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                {/* <Box
                  sx={{
                    background: "url('/explorer-slider-bg.png')",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    aspectRatio: {
                      xs: 1.24,
                    },
                  }}
                ></Box> 
                <Typography
                  sx={{
                    backgroundColor: "#192C39",
                    px: { xs: "44px", md: "56px" },
                    py: { xs: "2rem", md: "56px" },
                    // flexGrow: 1,
                    justifySelf: "flex-end",
                    fontSize: { md: "1.5rem", xs: "1rem" },
                  }}
                >
                  {instruction.instruction}
                </Typography>
              </Stack>
            </Box>
          ))}
        </Slider> */}
      </Box>
    </Box>
  );
};

export default ExplorerIntroduction;
