import { Breadcrumbs, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

const BreadcrumbsComp = ({ page }: { page: string }) => {
  const {
    customColors: { primary },
  } = useTheme();
  return (
    <Breadcrumbs
      separator={
        <Typography sx={{ color: primary.prusianBlue }}>&gt;</Typography>
      }
      aria-label="breadcrumb"
      sx={{
        color: primary.prusianBlue,
        "& a, p": {
          fontSize: "24px",
        },
        mt: "30px",
      }}
    >
      <Link
        to="/data-provenance-explorer"
        style={{ fontWeight: 500, color: "inherit", textDecoration: "none" }}
      >
        Data Provenance Explorer
      </Link>

      <Typography sx={{ fontWeight: 700 }}>{page}</Typography>
    </Breadcrumbs>
  );
};

export default BreadcrumbsComp;
