import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";

export default function VisualizationContainer({
  content,
}: {
  content?: HTMLElement | SVGSVGElement;
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const container = containerRef.current!;
    if (content) {
      container.appendChild(content);
    }

    return () => {
      if (content) {
        content.remove();
      }
    };
  }, [content]);
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center" }}
      ref={containerRef}
    ></Box>
  );
}
