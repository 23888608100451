import * as Plot from "@observablehq/plot";

// const brands = [
//   { name: "Apple", value: 214480 },
//   { name: "Google", value: 155506 },
//   { name: "Amazon", value: 100764 },
//   { name: "Microsoft", value: 92715 },
//   { name: "Coca-Cola", value: 66341 },
//   { name: "Samsung", value: 59890 },
//   { name: "Toyota", value: 53404 },
//   { name: "Mercedes-Benz", value: 48601 },
//   { name: "Facebook", value: 45168 },
//   { name: "McDonald's", value: 43417 },
//   { name: "Intel", value: 43293 },
// ];

export default function createBarChart(brands_temp: any) {
  const brands = convertObject(brands_temp);

  // Sample data (replace with actual data)
  const chart = Plot.plot({
    marginLeft: 200,
    grid: true,
    height: 400,
    width: 900,
    // x: { axis: null },
    title: "License Distribution",
    y: { label: "Category" },
    marks: [
      Plot.barX(brands, {
        x: "value",
        y: "name",
        sort: { y: "x", reverse: true, limit: 10 },
      }),

      Plot.text(brands, {
        text: (d) => `${d.value}`,
        y: "name",
        x: "value",
        textAnchor: "end",
        dx: -3,
        fill: "white",
      }),
    ],
  });

  return chart;
}
function convertObject(input: {
  [key: string]: any;
}): { name: string; value: any }[] {
  return Object.entries(input).map(([key, value]) => ({
    name: key,
    value: value,
  }));
}
