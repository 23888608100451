export default function prepCollectionTable(df, originalDf, metrics) {
  let table = {
    Collection: [],
    "# Datasets": [],
    "# Exs": [],
    "# Languages": [],
    "# Tasks": [],
    "# Topics": [],
    "# Sources": [],
    "Generated By": [],
    "Mean Input Chars": [],
    "Mean Target Chars": [],
    "% Datasets Used": [],
  };

  for (let collection in metrics.collections) {
    table.Collection.push(collection);
    let subsetDf = Object.values(df).filter(
      (row) => row.Collection === collection,
    );
    let originalSubsetDf = Object.values(originalDf).filter(
      (row) => row.Collection === collection,
    );
    let originalDatasets = new Set(
      originalSubsetDf.map((row) => row["Unique Dataset Identifier"]),
    );
    let subsetDatasets = new Set(
      Object.values(subsetDf).map((row) => row["Unique Dataset Identifier"]),
    );
    let subsetLangs = new Set(
      Object.values(subsetDf).flatMap((row) => row.Languages),
    );
    let subsetTaskcats = new Set(
      Object.values(subsetDf).flatMap((row) => row["Task Categories"]),
    );
    let subsetTopics = new Set(
      Object.values(subsetDf).flatMap((row) => {
        if (Array.isArray(row["Inferred Metadata"])) {
          return row["Inferred Metadata"].flatMap(
            (item) => item["Text Topics"] || [],
          );
        } else {
          return [];
        }
      }),
    );
    let subsetSources = new Set(
      Object.values(subsetDf).flatMap((row) => row["Text Sources"]),
    );
    let subsetModelGen = Array.from(
      new Set(Object.values(subsetDf).flatMap((row) => row["Model Generated"])),
    ).sort((a, b) => {
      return (a.includes("OpenAI") ? -1 : 1) - (b.includes("OpenAI") ? -1 : 1);
    });
    let selectedModel =
      subsetModelGen.length > 0
        ? subsetModelGen.find((model) => model.includes("OpenAI")) ||
          subsetModelGen[0]
        : "";
    let subsetMeanInpLen =
      Object.values(subsetDf).reduce(
        (acc, row) =>
          acc + (row["Text Metrics"] || {})["Mean Inputs Length"] ?? 0,
        0,
      ) / Object.values(subsetDf).length;
    let subsetMeanTarLen =
      Object.values(subsetDf).reduce(
        (acc, row) =>
          acc + (row["Text Metrics"] || {})["Mean Targets Length"] ?? 0,
        0,
      ) / Object.values(subsetDf).length;
    let totalDialogs = Object.values(subsetDf).reduce(
      (acc, row) =>
        acc +
        (row["Text Metrics"] ? row["Text Metrics"]["Num Dialogs"] || 0 : 0),
      0,
    );

    table["# Datasets"].push(subsetDatasets.size);
    table["# Exs"].push(totalDialogs);
    table["# Languages"].push(subsetLangs.size);
    table["# Tasks"].push(subsetTaskcats.size);
    table["# Topics"].push(subsetTopics.size);
    table["# Sources"].push(subsetSources.size);
    table["Generated By"].push(selectedModel);
    table["Mean Input Chars"].push(Math.round(subsetMeanInpLen * 10) / 10);
    table["Mean Target Chars"].push(Math.round(subsetMeanTarLen * 10) / 10);
    table["% Datasets Used"].push(
      `${Math.round(((100 * subsetDatasets.size) / originalDatasets.size) * 10) / 10} %`,
    );
  }

  return table;
}
